import { useState } from "react";
import "./TagGroupItem.scss";
import { TagsIcon } from "../../../../components/icons/TagsIcon";
import { IconButton } from "@mui/material";
import { DeleteIcon } from "../../../../components/icons/DeleteIcon";
import { PencilIcon } from "../../../../components/icons/PencilIcon";
import { TagsModal } from "components/modals/TagsModal/TagsModal";
const TagGroupItem = ({ name, visibleName, id }: any) => {
  const [tagsModalIsOpen, setTagsModalIsOpen] = useState(false);
  return (
    <>
      <div className="tag-group-item-container">
        <a
          onClick={() => {
            setTagsModalIsOpen(true);
          }}
        >
          <TagsIcon />
          <span>{visibleName}</span>
        </a>
        <div>
          <IconButton aria-label="delete" onClick={() => { }}>
            <PencilIcon />
          </IconButton>
          <IconButton aria-label="delete" onClick={() => { }}>
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
      <TagsModal
        open={tagsModalIsOpen}
        setOpen={setTagsModalIsOpen}
        visibleGroupName={visibleName}
        groupName={name}
        groupId={id}
        onClose={() => { }}
        onDismiss={(data: any) => {
          console.log(data);
        }}
      />
    </>
  );
};

export { TagGroupItem };
