import { useState, useEffect } from "react";
import { DateCell } from "../cells/DateCell/DateCell";
import { TagsCell } from "../cells/TagsCell/TagsCell";
import { ThumbnailCell } from "../cells/ThumbnailCell/ThumbnailCell";
import { MoneyCell } from "../cells/MoneyCell/MoneyCell";
import { formatNumber } from "utils";
import { StatusCell } from "components/cells/StatusCell/StatusCell";
import * as ev from "expr-eval";

const DynamicDataTableRow = ({ data, cols }: any) => {
  const evaluateExpression = (expression: any, data: any) => {
    try {
      // Crear una instancia del parser
      const parser = new ev.Parser();

      // Descomponer la expresión en sus partes (propiedades)
      const parts = expression.split('.');
      let currentData = data;
      // Verificar si cada parte de la expresión existe en el objeto `data`
      for (const part of parts) {
        if (currentData.hasOwnProperty(part)) {
          currentData = currentData[part];
        } else {
          // Si alguna parte de la expresión no existe en `data`, retornar "-"
          return "-";
        }
      }

      // Si todas las partes existen, evaluar la expresión
      // Esto es seguro ya que sabemos que `data` tiene todas las propiedades necesarias
      return parser.parse(expression).evaluate(data);
    } catch (error) {
      console.error("Error al evaluar la expresión:", error);
      return "-";
    }
  };
  return (
    <tr>
      {cols.map((col: any, subIndex: number) => (
        <td
          key={subIndex}
          data-column-header={col["visibleName"]}
          style={{ width: col.type == "thumbnail" ? "70px" : "auto" }}
        >
          {(() => {
            switch (col.type) {
              case "checkbox":
                return <span>{data[col.name] ? "Si" : "No"}</span>;
              case "visibleId":
                return <span>{data.visible_id}</span>;
              case "date":
                return (
                  <span>
                    <DateCell
                      data={data[col.name]}
                      extraData={data}
                      config={col}
                    />
                  </span>
                );
              case "tags":
                return (
                  <span>
                    <TagsCell data={data[col.name]} />
                  </span>
                );
              case "status":
                return (
                  <span>
                    <StatusCell
                      data={data[col.name]}
                      options={col?.options}
                      readonly={true}
                      icon={col.icon}
                    />
                  </span>
                );
              case "thumbnail":
                return (
                  <span>
                    <ThumbnailCell data={data[col.name]} />
                  </span>
                );
              case "money":
                return (
                  <span>
                    <MoneyCell data={data[col.name]} />
                  </span>
                );
              case "text":
                return (
                  <span
                    style={{
                      textAlign: col.type === "text" ? "left" : "right",
                    }}
                  >
                    {col.prepend && <strong> {col.prepend} </strong>}

                    {
                      evaluateExpression(col.name, data)
                    }
                    {col.append && <strong> {col.append} </strong>}
                  </span>
                );
              case "integer":
              case "decimal":
                return (
                  <span
                    style={{
                      textAlign: col.type === "text" ? "left" : "right",
                    }}
                  >
                    {col.prepend && <strong> {col.prepend} </strong>}
                    {data[col.name] == null
                      ? "-"
                      : formatNumber(
                        data[col.name],
                        col.type == "integer" ? 0 : 2
                      )}
                    {col.append && <strong> {col.append} </strong>}
                  </span>
                );
              default:
                return <span>{data[col.name] || "-"}</span>;
            }
          })()}
        </td>
      ))}
    </tr>
  );
};

export { DynamicDataTableRow };
