export const getFields = () => {
  const data: any = {
    task: [
      {
        type: "longText",
        visibleName: "Descripción",
        name: "description",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },

      {
        type: "date",
        visibleName: "Fecha de vencimiento",
        name: "due_date",
        description: null,
        size: 6,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "integer",
        visibleName: "Duración estimada (Horas)",
        name: "duration",
        description: null,
        size: 6,
        editable: null,
        editableIf: null,
        append: "Hs",
        defaultValue: 1,
        validations: {},
      },
      {
        type: "tags",
        visibleName: "Etiquetas",
        name: "tags",
        description: null,
        size: 12,
        tagGroup: {
          id: 5,
        },
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "images",
        visibleName: "Imagenes antes",
        name: "before_images",
        description: null,
        size: 12,
        editable: null,
        editableIf: null,
        validations: {
          maxItems: 5
        },
      },
      {
        type: "images",
        visibleName: "Imagenes después",
        name: "before_images",
        description: null,
        showOnEdit: true,
        size: 12,
        editable: null,
        editableIf: null,
        validations: { maxItems: 3 },
      },
      {
        type: "videos",
        visibleName: "Video Antes de la Tarea",
        name: "before_video",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: { maxItems: 3 },
      },
      {
        type: "videos",
        visibleName: "Video Después de la Tarea",
        name: "after_video",
        description: null,
        showOnEdit: true,
        size: 12,
        editable: true,
        editableIf: null,
        validations: { maxItems: 3 },
      },
      {
        type: "checklist",
        visibleName: "Subtareas",
        name: "subtasks",
        description: null,
        size: 12,
        editable: null,
        editableIf: null,
        validations: {},
      },
    ],
    myTask: [
      {
        type: "longText",
        visibleName: "Descripción",
        name: "description",
        description: null,
        size: 12,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "date",
        visibleName: "Fecha de vencimiento",
        name: "due_date",
        description: null,
        size: 6,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "integer",
        visibleName: "Duración estimada (Horas)",
        name: "duration",
        description: null,
        size: 6,
        editable: null,
        editableIf: null,
        append: "Hs",
        defaultValue: 1,
        validations: {},
      },
      {
        type: "tags",
        visibleName: "Etiquetas",
        name: "tags",
        description: null,
        size: 12,
        tagGroup: {
          id: 5,
        },
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "images",
        visibleName: "Imagenes antes",
        name: "before_images",
        description: null,
        size: 12,
        editable: null,
        editableIf: null,
        validations: { maxItems: 3 },
      },
      {
        type: "images",
        visibleName: "Imagenes antes",
        name: "after_images",
        description: null,
        size: 12,
        editable: null,
        editableIf: null,
        validations: { maxItems: 3 },
      },
      {
        type: "checklist",
        visibleName: "Checklist",
        name: "checklist",
        description: null,
        size: 12,
        editable: null,
        editableIf: null,
        validations: {},
      },
    ],
  };

  return data;
};
