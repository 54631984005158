import { useState, useEffect, Fragment } from "react";
import urls from "config/urls";
import AxiosInterceptor from "../../AxiosInterceptor";

import { Grid } from "@mui/material";
import { RadioField } from "../fields/RadioField/RadioField";
import "./DynamicForm.scss";
import { InputField } from "../fields/InputField/InputField";
import { PasswordField } from "../fields/PasswordField/PasswordField";
import { CheckboxField } from "../fields/CheckboxField/CheckboxField";
import { CheckboxesField } from "../fields/CheckboxesField/CheckboxesField";
import { ImageCropperField } from "../fields/ImageCropperField/ImageCropperField";
import { SelectField } from "../fields/SelectField/SelectField";
import { PickerField } from "../fields/PickerField/PickerField";
import * as ev from "expr-eval";
import { LongTextField } from "../fields/LongTextField/LongTextField";
import { TagsField } from "../fields/TagsField/TagsField";
import { DateField } from "../fields/DateField/DateField";
import { MoneyField } from "../fields/MoneyField/MoneyField";
import { MultipleSelectField } from "../fields/MultipleSelectField/MultipleSelectField";
import { Spinner } from "../Spinner/Spinner";
import { PeriodicityField } from "../fields/PeriodicityField/PeriodicityField";
import ListField from "../fields/ListField/ListField";
import { GridField } from "../fields/GridField/GridField";
import { AddressField } from "../fields/AddressField/AddressField";
import { CoordinatesField } from "../fields/CoordinatesField/CoordinatesField";
import { CardsPickerField } from "../fields/CardsPickerField/CardsPickerField";
import { ImagesField } from "../fields/ImagesField/ImagesField";
import { ProductVariantsField } from "../fields/ProductVariantsField/ProductVariantsField";
import { ImageField } from "../fields/ImageField/ImageField";
import { TextEditorField } from "../fields/TextEditorField/TextEditorField";
import { TextEditorFieldProvider } from "../fields/TextEditorField/TextEditorFieldContext";
import { ColorField } from "../fields/ColorField/ColorField";
import { PastelColorField } from "../fields/PastelColorField/PastelColorField";
import FormBuilderField from "../fields/FormBuilderField/FormBuilderField";
import { UsersField } from "../fields/UsersField/UsersField";
import { UserGroupsField } from "../fields/UserGroupsField/UserGroupsField";
import { TransactionField } from "../fields/TransactionField/TransactionField";
import { PaymentField } from "../fields/PaymentField/PaymentField";
import { ChecklistField } from "components/fields/ChecklistField/ChecklistField";
import SimplePaymentField from "components/fields/SimplePaymentField/SimplePaymentField";
import { CameraImageField } from "components/fields/CameraImageField/CameraImageField";
import { BillingField } from "components/fields/BillingField/BillingField";
import ConfigurationField from "components/fields/ConfigurationField/ConfigurationField";

import { VideoField } from "components/fields/VideoField/VideoField";
import { VideosField } from "components/fields/VideosField/VideosField";
const DynamicForm = (props: any) => {
  let {
    title,
    fields,
    onChange,
    onFieldEdit,
    tableName,
    recordId,
    readonly,
    extraData,
    formData,
    mode, // edit or create
    //globalEdit,
  } = props;
  const [loading, setLoading] = useState(false);

  let initialValues: any = {};

  fields.map((field: any) => {
    switch (field.type) {
      case "radio":
        initialValues[field.name] = field.defaultValue || field.options[0].name;
        break;
      case "date":
        initialValues[field.name] = null;
        break;
      case "checkbox":
        initialValues[field.name] = field.defaultValue || false;
        break;
      case "color":
        initialValues[field.name] = "#666";
        break;
      case "pastelColor":
        initialValues[field.name] = "#A597CC";
        break;
      case "list":
        initialValues[field.name] = field.defaultValue || [];
        break;
      case "checklist":
        initialValues[field.name] = field.defaultValue || [];
        break;

      case "images":
        initialValues[field.name] = field.defaultValue || [];
        break;
      case "videos":
        initialValues[field.name] = field.defaultValue || [];
        break;
      case "integer":
        initialValues[field.name] = field.defaultValue || null;
        break;
      case "grid":
        initialValues[field.name] = field.defaultValue || [];
        break;
      case "configuration":
        initialValues[field.name] = field.defaultValue || [];
        break;
      case "money":
        initialValues[field.name] = field.defaultValue || null;
        break;
      case "picker":
        if (field.validations?.maxItems === 1) {
          //initialValues[field.name] = field.defaultValue || "";
          initialValues[field.name] = field.defaultValue || null;
        } else {
          initialValues[field.name] = field.defaultValue || [];
        }
        break;
      case "text":
        break;
      case "decimal":
        initialValues[field.name] = field.defaultValue || null;
        break;
      case "simplePayment":
        initialValues[field.name] = {
          paymentMethods: [
            {
              name: "cash",
              visibleName: "Efectivo",
              amount: 0,
            },
            {
              name: "credit_card",
              visibleName: "Tarjeta de crédito",
              amount: 0,
            },
            {
              name: "debit_card",
              visibleName: "Tarjeta de débito",
              amount: 0,
            },
            {
              name: "mercado_pago",
              visibleName: "Mercado Pago",
              amount: 0,
            },
            {
              name: "bank_payment",
              visibleName: "Pago bancario",
              amount: 0,
            },
          ],
          totalPaid: 0,
        };
        break;

      default:
        break;
    }
  });

  // const [values, setValues] = useState(initialValues as any);
  const [values, setValues] = useState({
    ...initialValues,
    ...formData,
    ...extraData,
  } as any);
  //const [values, setValues] = useState(initialValues as any);
  useEffect(() => {
    console.log("formDatass", formData);
    // console.log("fields", fields);
    // if (formData) setValues(formData);
    setValues({
      ...initialValues,
      ...formData,
      ...extraData,
    });
  }, [formData]);
  const loadData = async (id: number) => {
    if (tableName) tableName = tableName?.replace(/_/g, "-");
    let url = `${urls.server}/api/${tableName}/${id}`;

    setLoading(true);
    const res = await AxiosInterceptor.get(`${url}`);
    const data = await res.data;
    setValues({
      ...data,
      ...extraData,
    });
    setLoading(false);
    console.log(data);
  };
  useEffect(() => {
    console.log(recordId);
    if (recordId) {
      loadData(recordId);
    }
  }, [recordId]);

  const handleChangeField = (
    fieldName: any,
    value: any,
    fieldType?: string
  ) => {
    setValues((prev: any) => {
      const data = { ...prev };
      data[fieldName] = value;
      if (fieldType == "select") data[`${fieldName}_id`] = value?.id;
      return data;
    });
  };
  useEffect(() => {
    onChange(values);
  }, [values]);
  const showIf = (exp: any) => {
    return exp ? ev.Parser.evaluate(exp, values) : true;
  };
  return (
    <div className="dynamic-form-container">
      {loading && (
        <div className="spinner-wrapper">
          <Spinner visible={loading} />
        </div>
      )}
      {!loading && (
        <form style={{ paddingTop: title ? "70px" : "0px" }}>
          {title ? <h2>{title}</h2> : ""}

          <div>
            <Grid container spacing={2}>
              {fields.map((field: any, index: number) => {
                const fieldName = field.name.replace(/_/g, "-");
                const shouldShowField = (!field.showOnEdit || (field.showOnEdit && mode === 'edit')) && ((field.showIf && showIf(field.showIf)) || !field.showIf);
                return (
                  <Fragment key={index}>
                    {shouldShowField && (
                      <Grid item xs={12} sm={field.size}>
                        {(() => {
                          switch (field.type) {
                            case "text":
                              return (
                                <InputField
                                  type="text"
                                  id={field.name}
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  editable={field.editable}
                                  name={field.name}
                                  prepend={field.prepend}
                                  append={field.append}
                                  tableName={tableName}
                                  recordId={recordId}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                  onEdit={onFieldEdit}
                                  description={field.description}
                                  readonly={readonly}
                                  mode={mode}
                                />
                              );
                            case "money":
                              return (
                                <MoneyField
                                  id={field.name}
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  editable={field.editable}
                                  name={field.name}
                                  tableName={tableName}
                                  recordId={recordId}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                  description={field.description}
                                  readonly={readonly}
                                  mode={mode}
                                />
                              );
                            case "periodicity":
                              return (
                                <PeriodicityField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  editable={field.editable}
                                  name={field.name}
                                  tableName={tableName}
                                  recordId={recordId}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                  readonly={readonly}
                                  mode={mode}
                                />
                              );
                            case "decimal":
                              return (
                                <InputField
                                  type="decimal"
                                  id={field.name}
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  editable={field.editable}
                                  name={field.name}
                                  tableName={tableName}
                                  recordId={recordId}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                  readonly={readonly}
                                  mode={mode}
                                  prepend={field.prepend}
                                  append={field.append}
                                  description={field.description}
                                  onEdit={onFieldEdit}
                                />
                              );
                            case "integer":
                              return (
                                <InputField
                                  type="integer"
                                  id={field.name}
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  editable={field.editable}
                                  name={field.name}
                                  tableName={tableName}
                                  recordId={recordId}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                  readonly={readonly}
                                  mode={mode}
                                  prepend={field.prepend}
                                  append={field.append}
                                  description={field.description}
                                  onEdit={onFieldEdit}
                                />
                              );
                            case "longText":
                              return (
                                <LongTextField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  editable={field.editable}
                                  name={field.name}
                                  placeholder={field.placeholder}
                                  tableName={tableName}
                                  recordId={recordId}
                                  readonly={readonly}
                                  mode={mode}
                                  id={field.name}
                                  onEdit={onFieldEdit}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                />
                              );
                            case "date":
                              return (
                                <DateField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  editable={field.editable}
                                  name={field.name}
                                  tableName={tableName}
                                  recordId={recordId}
                                  format={field.format}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                  readonly={readonly}
                                  mode={mode}
                                />
                              );
                            case "email":
                              return (
                                <InputField
                                  type="email"
                                  id={field.name}
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  name={field.name}
                                  tableName={tableName}
                                  recordId={recordId}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                />
                              );
                            case "password":
                              return (
                                <PasswordField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  name={field.name}
                                  tableName={tableName}
                                  recordId={recordId}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                />
                              );
                            case "radio":
                              return (
                                <>
                                  <RadioField
                                    label={field.visibleName}
                                    onChange={(value: any) =>
                                      handleChangeField(field.name, value)
                                    }
                                    defaultValue={values[field.name]}
                                    options={field.options}
                                    readonly={readonly}
                                    mode={mode}
                                    name={field.name}
                                    //tableName={tableName}
                                    //recordId={recordId}

                                    editable={field.editable}

                                    tableName={tableName}
                                    recordId={recordId}
                                    editPath={
                                      field.editPath ||
                                      `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(/_/g, "-")}`
                                    }
                                    onEdit={onFieldEdit}
                                    description={field.description}

                                  />
                                </>
                              );
                            case "configuration":
                              return (
                                <ConfigurationField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  editable={field.editable}
                                  name={field.name}
                                  configurationType={field.configurationType}
                                  tableName={tableName}
                                  recordId={recordId}
                                  readonly={readonly}
                                  mode={mode}
                                  id={field.name}
                                  onEdit={onFieldEdit}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(/_/g, "-")}`
                                  }
                                />
                              );

                            case "checkbox":
                              return (
                                <CheckboxField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  editable={field.editable}
                                  name={field.name}
                                  tableName={tableName}
                                  recordId={recordId}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                  description={field.description}
                                  readonly={readonly}
                                  mode={mode}
                                  onEdit={onFieldEdit}
                                //globalEdit={globalEdit}
                                />
                              );
                            case "checkboxes":
                              return (
                                <CheckboxesField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  description={field.description}
                                  loadPath={field.loadPath}
                                  readonly={readonly}
                                  mode={mode}
                                  name={field.name}
                                  tableName={tableName}
                                  recordId={recordId}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                />
                              );
                            case "picker":
                              return (
                                <PickerField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  description={field.description}
                                  options={field.options}
                                  validations={field.validations}
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  name={field.name}
                                  tableName={tableName}
                                  recordId={recordId}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                  editable={field.editable}
                                />
                              );
                            case "list":
                              return (
                                <ListField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  description={field.description}
                                  validations={field.validations}
                                  defaultValue={values[field.name]}
                                  itemPlaceholder={field.itemPlaceholder}
                                  readonly={readonly}
                                  mode={mode}
                                  name={field.name}
                                  options={field.options}
                                  tableName={tableName}
                                  recordId={recordId}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                  editable={field.editable}
                                //defaultValue={field.defaultValue}
                                />
                              );
                            case "checklist":
                              return (
                                <ChecklistField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  description={field.description}
                                  validations={field.validations}
                                  defaultValue={values[field.name]}
                                  //defaultValue={field.defaultValue}
                                  readonly={readonly}
                                  mode={mode}
                                  name={field.name}
                                  tableName={tableName}
                                  recordId={recordId}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                />
                              );
                            case "imageCropper":
                              return (
                                <ImageCropperField
                                  id={field.name}
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  aspect={field.aspect}
                                  editable={field.editable}
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  folder={field.folder}
                                  name={field.name}
                                  tableName={tableName}
                                  recordId={recordId}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                  onEdit={onFieldEdit}
                                />
                              );
                            case "images":
                              return (
                                <ImagesField
                                  id={field.name}
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  editable={field.editable}
                                  folder={field.folder}
                                  maxItems={field.validations.maxItems}
                                  name={field.name}
                                  tableName={tableName}
                                  recordId={recordId}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                />
                              );
                            case "image":
                              return (
                                <ImageField
                                  id={field.name}
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  folder={field.folder}
                                  name={field.name}
                                  tableName={tableName}
                                  recordId={recordId}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                />
                              );
                            case "video":
                              return (
                                <VideoField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  editable={field.editable}
                                  name={field.name}
                                  placeholder={field.placeholder}
                                  tableName={tableName}
                                  recordId={recordId}
                                  readonly={readonly}
                                  mode={mode}
                                  id={field.name}
                                  onEdit={onFieldEdit}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                />
                              );
                            case "videos":
                              return (
                                <VideosField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  editable={field.editable}
                                  name={field.name}
                                  placeholder={field.placeholder}
                                  tableName={tableName}
                                  recordId={recordId}
                                  readonly={readonly}
                                  mode={mode}
                                  id={field.name}
                                  onEdit={onFieldEdit}
                                  maxItems={field.validations.maxItems}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                />
                              );
                            case "cameraImage":
                              return (
                                <CameraImageField
                                  id={field.name}
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  folder={field.folder}
                                  name={field.name}
                                  tableName={tableName}
                                  recordId={recordId}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                />
                              );
                            case "grid":
                              return (
                                <GridField
                                  label={field.visibleName}
                                  cols={field.cols}
                                  onChange={(value: any) => {
                                    console.log(value);
                                    handleChangeField(field.name, value);
                                  }}
                                  defaultValue={values[field.name]}
                                  description={field.description}
                                  readonly={readonly}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                  tableName={tableName}
                                  recordId={recordId}
                                  name={field.name}
                                  formData={values}
                                  extraData={extraData}
                                  forceMobileView={field.forceMobileView}
                                  canRemoveRows={field.canRemoveRows}
                                  canAddRows={field.canAddRows}
                                />
                              );
                            case "select":
                              return (
                                <SelectField
                                  //id={field.name}
                                  defaultValue={values[field.name]}
                                  label={field.visibleName}
                                  tableName={tableName}
                                  searchPath={field.searchPath}
                                  searchFields={field.searchFields}
                                  primaryKey={field.primaryKey}
                                  editable={field.editable}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                  recordId={recordId}
                                  formFields={field.formFields}
                                  formTitle={field.formTitle}
                                  formTableName={field.formTableName}
                                  filters={field.filters}
                                  onChange={(value: any) =>
                                    handleChangeField(
                                      field.name,
                                      value,
                                      field.type
                                    )
                                  }
                                  readonly={readonly}
                                  mode={mode}
                                  name={field.name}
                                />
                              );
                            case "multipleSelect":
                              return (
                                <MultipleSelectField
                                  label={field.visibleName}
                                  tableName={tableName}
                                  searchPath={field.searchPath}
                                  primaryKey={field.primaryKey}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  readonly={readonly}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                  recordId={recordId}
                                  name={field.name}
                                />
                              );
                            case "tags":
                              return (
                                <TagsField
                                  label={field.visibleName}
                                  groupId={field.tagGroup?.id}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                  recordId={recordId}
                                  name={field.name}
                                  onEdit={onFieldEdit}
                                  tableName={tableName}
                                />
                              );
                            case "address":
                              return (
                                <AddressField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                  recordId={recordId}
                                  name={field.name}
                                  tableName={tableName}
                                />
                              );

                            case "productVariants":
                              return (
                                <ProductVariantsField
                                  formData={values}
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                  recordId={recordId}
                                  name={field.name}
                                  tableName={tableName}
                                />
                              );
                            case "cardsPicker":
                              return (
                                <CardsPickerField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(
                                      /_/g,
                                      "-"
                                    )}`
                                  }
                                  recordId={recordId}
                                  name={field.name}
                                  tableName={tableName}
                                />
                              );

                            case "coordinates":
                              return (
                                <CoordinatesField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(/_/g, "-")}`
                                  }
                                  recordId={recordId}
                                  name={field.name}
                                  tableName={tableName}
                                />
                              );
                            case "productVariants":
                              return (
                                <ProductVariantsField
                                  formData={values}
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(/_/g, "-")}`
                                  }
                                  recordId={recordId}
                                  name={field.name}
                                  tableName={tableName}
                                />
                              );
                            case "cardsPicker":
                              return (
                                <CardsPickerField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(/_/g, "-")}`
                                  }
                                  recordId={recordId}
                                  name={field.name}
                                  tableName={tableName}
                                />
                              );
                            case "textEditor":
                              return (
                                <TextEditorFieldProvider key={field.name}>
                                  <TextEditorField
                                    label={field.visibleName}
                                    onChange={(value: any) =>
                                      handleChangeField(field.name, value)
                                    }
                                    defaultValue={values[field.name]}
                                    readonly={readonly}
                                    mode={mode}
                                    editable={field.editable}
                                    editPath={
                                      field.editPath ||
                                      `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(/_/g, "-")}`
                                    }
                                    recordId={recordId}
                                    name={field.name}
                                    tableName={tableName}
                                  />
                                </TextEditorFieldProvider>
                              );
                            case "color":
                              return (
                                <ColorField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(/_/g, "-")}`
                                  }
                                  recordId={recordId}
                                  name={field.name}
                                  tableName={tableName}
                                />
                              );
                            case "pastelColor":
                              return (
                                <PastelColorField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(/_/g, "-")}`
                                  }
                                  recordId={recordId}
                                  name={field.name}
                                  tableName={tableName}
                                />
                              );
                            case "formBuilder":
                              return (
                                <FormBuilderField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(/_/g, "-")}`
                                  }
                                  recordId={recordId}
                                  tableName={tableName}
                                  name={field.name}
                                  onEdit={onFieldEdit}
                                />
                              );
                            case "users":
                              return (
                                <UsersField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(/_/g, "-")}`
                                  }
                                  tableName={tableName}
                                  name={field.name}
                                  recordId={recordId}
                                />
                              );
                            case "userGroups":
                              return (
                                <UserGroupsField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(/_/g, "-")}`
                                  }
                                  recordId={recordId}
                                  name={field.name}
                                  tableName={tableName}
                                />
                              );
                            case "transaction":
                              return (
                                <TransactionField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(/_/g, "-")}`
                                  }
                                  recordId={recordId}
                                  transactionType={field.transactionType}
                                  documentType={field.documentType}
                                  name={field.name}
                                  tableName={tableName}
                                />
                              );
                            case "billing":
                              return (
                                <BillingField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(/_/g, "-")}`
                                  }
                                  recordId={recordId}
                                  transactionType={field.transactionType}
                                  documentType={field.documentType}
                                  name={field.name}
                                  tableName={tableName}
                                />
                              );
                            case "payment":
                              return (
                                <PaymentField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(/_/g, "-")}`
                                  }
                                  recordId={recordId}
                                  name={field.name}
                                  tableName={tableName}
                                  transactionType={field.transactionType}
                                />
                              );
                            case "simplePayment":
                              return (
                                <SimplePaymentField
                                  label={field.visibleName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  readonly={readonly}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    field.editPath ||
                                    `${tableName?.replace(/_/g, "-")}/${recordId}/${fieldName.replace(/_/g, "-")}`
                                  }
                                  recordId={recordId}
                                  name={field.name}
                                  tableName={tableName}
                                  transactionType={field.transactionType}
                                  formData={values}
                                />
                              );
                            case "separator":
                              return (
                                <div className="separator-field-container">
                                  {field.visibleName}
                                </div>
                              );
                          }
                        })()}
                      </Grid>
                    )}
                  </Fragment>
                );
              })}
            </Grid>
          </div>
          {recordId && (
            <span className="visible-id">{values["visible_id"]}</span>
          )}
        </form>
      )}
    </div>
  );
};

export { DynamicForm };
