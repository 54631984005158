import React from "react";
import Lottie from "lottie-react";
import saltaAnimationData from "../files/salta.json";
import cafayateAnimationData from "../files/cafayate.json";
import iruyaAnimationData from "../files/iruya.json";
import ChartFooterAnimation from "../ChartFooterAnimation/ChartFooterAnimation";
import './FooterAnimation.scss';

const FooterAnimation = ({ name }: any) => {
  let animationData = saltaAnimationData;
  switch (name) {
    case "salta":
      animationData = saltaAnimationData;
      break;
    case "cafayate":
      animationData = cafayateAnimationData;
      break;
    case "iruya":
      animationData = iruyaAnimationData;
      break;

    default:
      break;
  }
  return (
    <div
      className="footer-animation-container"
      style={{
        position: "fixed",
        bottom: "0px",
        left: 0,
        width: "100%"
      }}>
      <div
        style={{
          width: "100%",
          height: "0",
          paddingBottom: "24%",
          position: "relative",
        }}
      >
        {
          name == 'chart' ?
            <ChartFooterAnimation /> :
            <Lottie
              loop={false}
              animationData={animationData}
              style={{ position: "absolute", width: "100%", height: "100%" }}
            />
        }

      </div>
    </div>
  );
};

export default FooterAnimation;
