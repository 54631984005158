import React, { useState } from "react";
import "./IndicatorValueItem.scss";
import { LockToggleCell } from "../../../../components/cells/LockToggleCell/LockToggleCell";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Popover,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import urls from "config/urls";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import { IndicatorValueFormModal } from "../IndicatorValueFormModal/IndicatorValueFormModal";
import { PlayPauseCell } from "../../../../components/cells/PlayPauseCell/PlayPauseCell";
const IndicatorValueItem = (props: any) => {
  const { data, onChange, onDelete } = props;
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popoverIsOpen = Boolean(anchorEl);
  const presentPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closePopover = () => {
    setAnchorEl(null);
  };
  const closeAlert = () => {
    setAlertIsOpen(false);
  };
  const removeValue = async () => {
    setProcessing(true);
    const res = await AxiosInterceptor.delete(
      `${urls.server}/api/indicator-values/${data?.id}`
    );
    console.log(res);
    onDelete(true);
    setProcessing(false);
    closeAlert();
    closePopover();
  };

  return (
    <div className="indicator-value-item-container">
      <div>
        <span>{data.visible_period}</span>
      </div>
      <div>
        <div className="value-wrapper">
          <div>
            {data.value !== null && data.value !== undefined
              ? parseFloat(data.value).toLocaleString("es-AR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })
              : "-"}
          </div>
        </div>
        <div className="lock-toggle-wrapper">
          <LockToggleCell
            tableName={"indicator_values"}
            recordId={data.id}
            value={data.is_locked}
          ></LockToggleCell>
        </div>
        <div style={{ marginRight: "0px" }}>
          <PlayPauseCell
            editPath={`indicator-values/${data.id}/is-active`}
            value={data.is_active}
          />
        </div>
        <div className="more-actions-wrapper">
          <IconButton aria-label="actions" onClick={presentPopover}>
            <MoreVertIcon />
          </IconButton>
        </div>
      </div>
      <Popover
        id={"indicator_value_popover_" + data.id}
        open={popoverIsOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="popover-items">
          <a
            onClick={() => {
              setFormModalIsOpen(true);
              closePopover();
            }}
          >
            Editar valor
          </a>
          <a
            onClick={() => {
              setAlertIsOpen(true);
              closePopover();
            }}
          >
            Eliminar
          </a>
        </div>
      </Popover>
      <Dialog
        open={alertIsOpen}
        onClose={closeAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Quitar"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Desea eliminar el periodo <b>{data?.visible_period}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAlert} autoFocus>
            Cancelar
          </Button>
          <Button onClick={removeValue}>Aceptar</Button>
        </DialogActions>
      </Dialog>

      <IndicatorValueFormModal
        open={formModalIsOpen}
        setOpen={setFormModalIsOpen}
        onDismiss={(res: any) => { }}
        onClose={(res: any) => { }}
        indicatorValueId={data.id}
        value={data.value}
        displayPeriod={data.visible_period}
      />
    </div>
  );
};

export { IndicatorValueItem };
