import { useState, useEffect } from "react";
import { DocumentsIcon } from "components/icons/DocumentsIcon";
import { Spinner } from "components/Spinner/Spinner";
import { UpArrowIcon } from "components/icons/UpArrowIcon";
import { DownArrowIcon } from "components/icons/DownArrowIcon";
import LoadingButton from "@mui/lab/LoadingButton";
import { PlusIcon } from "components/icons/PlusIcon";
import "./DynamicTable.scss";
import { Alert, Snackbar } from "@mui/material";
import { DynamicTableRow } from "./DynamicTableRow";
import { useLoadRecords } from "hooks/useLoadRecords";

const DynamicTable = ({
  tableName,
  searchTerm,
  listPath,
  dividerFilters = [],
  filters = [],
  hiddenFilters = [],
  cols = [],
  refreshData,
  setRefreshData,
  rowActions,
  //tableActions,
  formFields = [],
  formTitle = [],
  onSelectRowAction,

}: any) => {
  const [offset, setOffset] = useState<number>(0);
  const limit = 5;
  // const [limit, setLimit] = useState<any>(5);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const {
    loadRecords,
    records,
    loading,
    loadingMore,
    hasMore,
    setHasMore,
  } = useLoadRecords({
    tableName,
    listPath,
    offset,
    limit,
    sortBy,
    sortDirection,
    dividerFilters,
    hiddenFilters,
    filters,
    searchTerm,
    setOffset,
    refreshData,

  });

  const handleCloseAlert = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertIsOpen(false);
  };
  useEffect(() => {
    setRefreshData(false);
  }, [records]);
  useEffect(() => {
    return () => {
      console.log("cierro dynamictable");
    };
  }, []);
  const sort = (col: any) => {
    if (col.sortable != false) {
      const sortBy = col.type === 'visibleId' ? 'id' : col.name;
      setSortDirection((prev: string) => {
        return prev == "asc" ? "desc" : "asc";
      });
      setSortBy(sortBy);
      setHasMore(true);
      setOffset(0);
      loadRecords({ offset: 0 });
    }
  };

  const getMore = () => {
    setOffset((prev: number) => prev + limit);
  };
  const resetState = () => {
    setHasMore(true);
    setOffset(0);
    loadRecords({ offset: 0 });
  }
  if (loading)
    return (
      <div className="spinner-wrapper">
        <Spinner visible={loading} />
      </div>
    );
  return (
    <div className="dynamic-table-container">
      {!loading && records.length === 0 && (
        <div className="empty-container">
          <DocumentsIcon />
          <p>No hay registros</p>
        </div>
      )}

      {!loading && records.length > 0 && cols.length > 0 && (
        <>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  {cols.map((col: any, index: number) => (
                    <th key={index}>
                      <a
                        href="#/"
                        onClick={() => sort(col)}
                        style={{
                          display: "block",
                          textAlign:
                            col.type == "money" || col.type == "decimal"
                              ? "right"
                              : "left",
                        }}
                      >
                        {sortDirection == "asc" && sortBy == col.name && (
                          <UpArrowIcon />
                        )}
                        {sortDirection == "desc" && sortBy == col.name && (
                          <DownArrowIcon />
                        )}
                        {col.type == 'visibleId' ? 'ID' : col.visibleName}
                      </a>
                    </th>
                  ))}

                  <th></th>
                </tr>
              </thead>
              <tbody>
                {records.map((item: any, index: number) => (
                  <DynamicTableRow
                    key={index}
                    data={item}
                    cols={cols}
                    actions={rowActions}
                    id={index}
                    tableName={tableName}
                    recordId={item.id}
                    formTitle={formTitle}
                    formFields={formFields}
                    resetState={resetState}
                    onSelectRowAction={onSelectRowAction}
                    onDeleteRecord={() => {
                      resetState()
                    }}
                    onEditRecord={() => {
                      resetState()
                    }}
                  />
                ))}
              </tbody>
            </table>

            {hasMore && (
              <div className="loading-button-wrapper">
                <LoadingButton
                  onClick={getMore}
                  loading={loadingMore}
                  loadingPosition="start"
                  startIcon={<PlusIcon />}
                >
                  Más registros
                </LoadingButton>
              </div>
            )}
          </div>
        </>
      )}
      <Snackbar
        open={alertIsOpen}
        autoHideDuration={4000}
        onClose={handleCloseAlert}
      >
        <Alert
          variant="filled"
          onClose={handleCloseAlert}
          severity="error"
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export { DynamicTable };