import { useState, useEffect } from "react";

import { CheckIcon } from "../../icons/CheckIcon";
import { CloseIcon } from "../../icons/CloseIcon";
import { PencilIcon } from "../../icons/PencilIcon";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import { Snackbar, Alert } from "@mui/material";
import "./BillingField.scss";
import { ReadonlyBillingField } from "./ReadonlyBillingField/ReadonlyBillingField";
import { EditableBillingField } from "./EditableBillingField/EditableBillingField";
const BillingField = (props: any) => {
  let {
    defaultValue,
    editable,
    editPath,
    onChange,
    recordId,
    readonly,
    transactionType,
    documentType,
    mode
  }: any = props;
  const [data, setData] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const enableEdition = () => {
    setIsEditEnabled(true);
  };
  useEffect(() => {
    if (onChange) {
      onChange(data);
    }
  }, [data]);
  const handleCloseAlert = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  return (
    <div className="billing-field-container">
      <div className="field-header">
        {(recordId || editPath) && editable && (
          <div>
            {isEditEnabled ? (
              <>
                <a
                  onClick={
                    //handleSave
                    () => { }
                  }
                >
                  <CheckIcon />
                </a>
                <a onClick={() => setIsEditEnabled(false)}>
                  <CloseIcon />
                </a>
              </>
            ) : (
              <>
                <a onClick={enableEdition}>
                  <PencilIcon />
                </a>
              </>
            )}
          </div>
        )}
      </div>
      <div className="field-body">
        <div>
          {readonly ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <ReadonlyBillingField
              data={defaultValue}
              transactionType={transactionType}
              documentType={documentType}
            />
          ) : (
            <EditableBillingField
              defaultData={defaultValue}
              transactionType={transactionType}
              documentType={documentType}
              onChange={onChange}
            />
          )}
        </div>
      </div>
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={handleCloseAlert}
      >
        <Alert
          variant="filled"
          onClose={handleCloseAlert}
          severity="error"
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export { BillingField };
