import { useState, useEffect } from "react";
import { CheckIcon } from "../../icons/CheckIcon";
import { CloseIcon } from "../../icons/CloseIcon";
import { PencilIcon } from "../../icons/PencilIcon";
import "./PeriodicityField.scss";
const PeriodicityField = (props: any) => {
  const { defaultValue, editable, label, editPath, onChange, mode }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [enabledEdition, setEnabledEdition] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(2);
  const options = [
    {
      name: "daily",
      visibleName: "Diaria",
    },
    {
      name: "weekly",
      visibleName: "Semanal",
    },
    {
      name: "monthly",
      visibleName: "Mensual",
    },
    {
      name: "bimonthly",
      visibleName: "Bimestral",
    },
    {
      name: "quarterly",
      visibleName: "Trimestral",
    },
    {
      name: "four-monthly",
      visibleName: "Cuatrimestral",
    },
    {
      name: "semiannually",
      visibleName: "Semestral",
    },
    {
      name: "annually",
      visibleName: "Anual",
    },
    {
      name: "biennially",
      visibleName: "Bianual",
    },
  ];
  const handleSave = () => {
    setEnabledEdition(false);
  };
  const enableEdition = () => {
    setEnabledEdition(true);
  };
  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  const handleClick = (index: number) => {
    setCurrentIndex(index);
  };
  useEffect(() => {
    setValue(options[currentIndex].name);
  }, [currentIndex]);
  return (
    <div className="periodicity-field-container">
      {label && (
        <label>
          <span>{label}</span>
        </label>
      )}
      <div>
        <div>
          {editable && !enabledEdition ? (
            <span>{value}</span>
          ) : (
            <div className="options">
              {options.map((option: any, index: any) => {
                return (
                  <a
                    key={index}
                    onClick={() => handleClick(index)}
                    className={currentIndex == index ? "actived" : ""}
                  >
                    <span>{option.visibleName}</span>
                  </a>
                );
              })}
            </div>
          )}
        </div>

        {editable && (
          <div>
            {enabledEdition ? (
              <>
                <button onClick={handleSave}>
                  <CheckIcon />
                </button>
                <button onClick={() => setEnabledEdition(false)}>
                  <CloseIcon />
                </button>
              </>
            ) : (
              <>
                <button onClick={enableEdition}>
                  <PencilIcon />
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { PeriodicityField };
