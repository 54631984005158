import { useState, useEffect } from "react";
import { DocumentsIcon } from "components/icons/DocumentsIcon";
import { Spinner } from "components/Spinner/Spinner";
import { UpArrowIcon } from "components/icons/UpArrowIcon";
import { DownArrowIcon } from "components/icons/DownArrowIcon";

import "./DynamicReportTable.scss";
import { Alert, Snackbar } from "@mui/material";
import { DynamicReportTableRow } from "./DynamicReportTableRow";

import { useLoadReportRecords } from "hooks/useLoadReportRecords";
import { useDebounce } from "hooks/useDebounce";

const DynamicReportTable = ({
  searchTerm,
  listPath,
  cols = [],
  reportName,
  forceRefresh,
  onChange,
  onSort,
  filters = [],
}: any) => {
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const debouncedValue = useDebounce<string>(searchTerm, 500);
  const { loadRecords, records, loading } = useLoadReportRecords({
    listPath,
    reportName,
    searchTerm,
    sortDirection,
    sortBy,
    filters
  });
  const handleCloseAlert = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertIsOpen(false);
  };

  useEffect(() => {
    return () => {
      console.log("cierro dynamictable");
    };
  }, []);
  useEffect(() => {
    loadRecords();
  }, [debouncedValue, filters]);
  useEffect(() => {
    if (forceRefresh) loadRecords();
  }, [forceRefresh]);
  useEffect(() => {
    if (onChange) onChange();
  }, [records]);

  useEffect(() => {
    loadRecords();
    onSort({
      sortBy,
      sortDirection
    })
  }, [sortBy, sortDirection]);


  const sort = (col: any) => {
    if (col.sortable != false) {
      setSortDirection((prev: string) => {
        return prev == "asc" ? "desc" : "asc";
      });
      setSortBy(col.name);
    }
  };

  return (
    <>
      {loading && (
        <div className="spinner-wrapper">
          <Spinner visible={loading} />
        </div>
      )}
      <div className="dynamic-report-table-container">
        { }
        {!loading && records.length === 0 && (
          <div className="empty-container">
            <DocumentsIcon />
            <p>No hay registros</p>
          </div>
        )}

        {!loading && records.length > 0 && cols.length > 0 && (
          <>
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    {cols.map((col: any, index: number) => (
                      <th key={index}>
                        <a
                          href="#/"
                          onClick={() => sort(col)}
                          style={{
                            display: "block",
                            textAlign:
                              col.type == "money" || col.type == "decimal"
                                ? "right"
                                : "left",
                          }}
                        >
                          {sortDirection == "asc" && sortBy == col.name && (
                            <UpArrowIcon />
                          )}
                          {sortDirection == "desc" && sortBy == col.name && (
                            <DownArrowIcon />
                          )}
                          {col.type == 'visibleId' ? 'ID' : col.visibleName}
                        </a>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {records.map((item: any, index: number) => (
                    <DynamicReportTableRow
                      key={index}
                      data={item}
                      cols={cols}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
        <Snackbar
          open={alertIsOpen}
          autoHideDuration={4000}
          onClose={handleCloseAlert}
        >
          <Alert
            variant="filled"
            onClose={handleCloseAlert}
            severity="error"
            sx={{ width: "100%" }}
          >
            {alertMsg}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export { DynamicReportTable };
