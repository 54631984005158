import React, { useEffect, useState } from 'react'
import Modal from 'components/Modal/Modal';
import './DataTableEntriesModal.scss';
import { DynamicDataTable } from 'components/DynamicDataTable/DynamicDataTable';


interface DataTableEntriesModalProps {
  title: string;
  isOpen: boolean;
  dataTableId: number;
  cols: any[];
  searchFields: any[];
  onClose: () => void;
}

const DataTableEntriesModal: React.FC<DataTableEntriesModalProps> = ({
  isOpen,
  title,
  dataTableId,
  cols = [],
  searchFields = [],
  onClose
}) => {
  const [refreshData, setRefreshData] = useState(false);
  const [downloadingReport, setDownloadingReport] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState([] as any);

  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");


  return (
    <>
      <Modal
        title={title}
        className={'board-modal-container'}
        isOpen={isOpen}
        onClose={onClose}
        hasSearchBar={true}
        filters={filters}
        onSearchChange={(searchTerm: string) => {
          console.log("searchTerm", searchTerm)
          setSearchTerm(searchTerm);
        }}
        onActiveFiltersChange={(filters) => {
          console.log("filters", filters);
          setFilters(filters);
        }}

      >

        <div>
          <DynamicDataTable
            cols={cols}
            searchFields={searchFields}
            searchTerm={searchTerm}
            dataTableId={dataTableId}
            filters={filters}
            onSort={(res: any) => {
              console.log('res', res);
              setSortBy(res.sortBy);
              setSortDirection(res.sortDirection);
            }}
          // forceRefresh={refreshData}
          // onChange={() => {
          //   setRefreshData(false);
          // }}
          />
        </div>

      </Modal>

    </>

  )
}

export { DataTableEntriesModal }