import Modal from 'components/Modal/Modal';
import React, { useEffect, useState } from 'react'
import ConfigurationItemSelector from './components/ConfigurationItemSelector/ConfigurationItemSelector';
import './ConfigurationBuilderModal.scss';
import SelectedConfigurationItems from './components/SelectedConfigurationItems/SelectedConfigurationItems';
import ConfigurationItemEditor from './components/ConfigurationItemEditor/ConfigurationItemEditor';
import { Fab } from '@mui/material';
import { CloseIcon } from 'components/icons/CloseIcon';
import { AddIcon } from 'components/icons/AddIcon';
import { CheckIcon } from 'components/icons/CheckIcon';
import recordService from 'services/recordService';
interface ConfigurationBuilderModalProps {
    isOpen: boolean;
    onClose: (value?: any) => void;
    type: 'fields' | 'cols' | 'filters' | 'rowActions' | 'importCols' | 'exportCols';
    data?: any;
}
const ConfigurationBuilderModal: React.FC<ConfigurationBuilderModalProps> = ({ isOpen, type, data, onClose }) => {
    const [selectedItems, setSelectedItems] = React.useState([] as any);
    const [selectedItemIndex, setSelectedItemIndex] = React.useState(null as any);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [configurationItemSelectorWrapperIsVisible, setConfigurationItemSelectorWrapperIsVisible] = useState(true);
    const [configurationItemEditorWrapperIsVisible, setConfigurationItemEditorWrapperIsVisible] = useState(false);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const fetchItems = async () => {
        setLoading(true);
        try {
            const res = await recordService.fetchAll({
                listPath: `configuration-items/${type}`,
            });
            console.log(res)
            setLoading(false);
            setItems(res);
        } catch (error: any) {
            setLoading(false)
        }
    };
    useEffect(() => {
        fetchItems()
    }, [])
    const toggleConfigurationItemSelectorWrapperVisibility = () => {
        setConfigurationItemSelectorWrapperIsVisible(!configurationItemSelectorWrapperIsVisible);
    };
    let title = '';
    switch (type) {
        case 'fields':
            title = "Armador de campos del formulario";
            break;
        case 'cols':
            title = "Armador de columnas";
            break;
        case 'filters':
            title = "Armador de filtros";
            break;
        case 'rowActions':
            title = "Armador de acciones";
            break;
        default:
            break;
    }
    const handleSelectItem = (newItem: any) => {
        setSelectedItems((prevItems: any) => [...prevItems, newItem]);
    };
    const handleEditItem = (item: any, index: number) => {
        console.log('Editar ítem:', index, item);
        setSelectedItemIndex(index)
        setItemToEdit(item);
        // Lógica para editar el ítem
    };

    const handleRemoveItem = (item: any, index: number) => {
        setSelectedItems((prevItems: any) => prevItems.filter((item: any, idx: number) => idx !== index));
        console.log('Eliminar ítem:', index, item);
        // Lógica para eliminar el ítem
    };
    const handleSortEnd = (items: any) => {
        console.log(items)
        setSelectedItems(items);
    };
    useEffect(() => {
        if (itemToEdit) {
            setConfigurationItemEditorWrapperIsVisible(true)
        }

    }, [itemToEdit])

    useEffect(() => {
        if (isOpen && data) {
            const transformedData = data.map((dataItem: any) => {
                // Buscar el ítem correspondiente de `items` basado en `type`
                const itemInfo: any = items.find((item: any) => item.type === dataItem.type);
                return {
                    ...itemInfo, // Esto establecerá `type`, `visible_type`, `icon` del ítem encontrado
                    configurable_attributes: { ...dataItem }, // Coloca todo el objeto de dataItem dentro de `configurable_attributes`
                };
            });
            setSelectedItems(transformedData);
        }
    }, [isOpen, data, items]);

    return (
        <Modal title={title} className={'configuration-builder-modal-container'} isOpen={isOpen} onClose={() => {
            onClose();
            setSelectedItems([]);
        }}>
            <div className={`configuration-item-selector-wrapper ${configurationItemSelectorWrapperIsVisible ? "visible" : "hidden"
                }`}
            >
                <a className="toggle-button" onClick={toggleConfigurationItemSelectorWrapperVisibility}>
                    {configurationItemSelectorWrapperIsVisible ? <CloseIcon /> : <AddIcon />}
                </a>
                <ConfigurationItemSelector items={items} onSelectItem={handleSelectItem} />
            </div>
            <div className='selected-configuration-items-wrapper'>
                <SelectedConfigurationItems
                    data={selectedItems}
                    onEditItem={handleEditItem}
                    onRemoveItem={handleRemoveItem}
                    onSortEnd={handleSortEnd}
                />
            </div>

            <ConfigurationItemEditor
                configurationType={type}
                data={itemToEdit}
                onHide={(modifiedItem) => {
                    if (modifiedItem) {
                        setSelectedItems((prevItems: any) =>
                            prevItems.map((item: any, index: number) =>
                                //item.id === modifiedItem.id ? modifiedItem : item
                                selectedItemIndex === index ? modifiedItem : item
                            )
                        );
                    }
                    setConfigurationItemEditorWrapperIsVisible(false);
                    setItemToEdit(null);
                }}
                isVisible={configurationItemEditorWrapperIsVisible} />

            <Fab
                className="fab"
                color="primary"
                aria-label="save"
                onClick={() => {
                    const attributesToSave = selectedItems.map((item: any) => item.configurable_attributes);
                    onClose(attributesToSave);
                    setSelectedItems([]);
                }}
                //disabled={selectedItems.length === 0}
                //style={{ opacity: selectedItems.length === 0 ? .6 : 1 }}
                sx={{
                    position: "fixed",
                    bottom: 16,
                    right: 16,
                    zIndex: 0
                }}
            >
                <CheckIcon />
            </Fab>
        </Modal>
    )

}

export default ConfigurationBuilderModal