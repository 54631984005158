import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import "./ConfigurationField.scss";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import ConfigurationBuilderModal from "components/modals/ConfigurationBuilderModal/ConfigurationBuilderModal";
import Icon from "components/Icon/Icon";

const ConfigurationField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    editPath,
    onChange,
    name,
    readonly,
    configurationType,
    mode
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);

  const [fieldsBuilderModalIsOpen, setFieldsBuilderModalIsOpen] =
    useState(false);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  return (
    <div className="configuration-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {editable && (
          <div>
            {!readonly != true || (mode == 'edit' && editable) && (
              <FieldEditControls
                value={value}
                fieldName={name}
                editPath={editPath}
                onEditStart={() => {
                  setIsEditEnabled(true);
                }}
                onEditCancel={(originalValue) => {
                  setIsEditEnabled(false);
                  setValue(originalValue);
                }}
                onEditEnd={(success: boolean, originalValue) => {
                  setIsEditEnabled(false);
                  if (!success) {
                    setValue(originalValue);
                  }
                }}
              />
            )}
          </div>
        )}
      </div>
      <div className="field-body">

        {readonly ||
          (mode == 'edit' && editable && !isEditEnabled) ||
          (mode == 'edit' && !editable) ? (
          <div className="readonly-content">
            {value && (

              <div className="items-count">
                <strong>Cantidad de items: </strong>
                <span className="ml-5">{value?.length}</span>
              </div>

            )}
          </div>
        ) : (
          <div className="editable-content">

            {value && (

              <div className="items-count">
                <strong>Cantidad de items:</strong>
                <span className="ml-5">{value?.length}</span>
              </div>

            )}
            <span className="btn-wrapper">
              <Button
                onClick={() => {
                  setFieldsBuilderModalIsOpen(true);
                }}
              >
                <Icon name="tools" /> Abrir armador
              </Button>
            </span>
          </div>
        )}

      </div>

      <ConfigurationBuilderModal
        isOpen={fieldsBuilderModalIsOpen}
        onClose={(data: any) => {
          if (data) {
            console.log(data);
            setValue((prev: any) => {
              let dataCopy: any = [...prev];
              dataCopy = data;
              return dataCopy;
            });
          }
          setFieldsBuilderModalIsOpen(false);
        }}
        data={value || []}
        type={configurationType}
      />
    </div>
  );
};

export default ConfigurationField;
