import { useEffect, useState } from "react";
import { FormModal } from "../../../../components/modals/FormModal/FormModal";
import { AddIcon } from "../../../../components/icons/AddIcon";
import { AddWrapperIcon } from "../../../../components/icons/AddWrapperIcon";
import { DeleteIcon } from "../../../../components/icons/DeleteIcon";
import { DeleteWrapperIcon } from "../../../../components/icons/DeleteWrapperIcon";
import { GridIcon } from "../../../../components/icons/GridIcon";
import { GridWrapperIcon } from "../../../../components/icons/GridWrapperIcon";
import { DeletedRecordsModal } from "../../../../components/modals/DeletedRecordsModal/DeletedRecordsModal";
import "./DataTableExpandedCard.scss";
import recordService from "services/recordService";
import { Spinner } from "components/Spinner/Spinner";
import { DataTableEntriesModal } from "components/modals/DataTableEntriesModal/DataTableEntriesModal";

const DataTableExpandedCard = (props: any) => {
  const { title, config } = props;
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  const [dataTableEntriesModalIsOpen, setDataTableEntriesModalIsOpen] = useState(false);

  const [deletedRecordsModalIsOpen, setDeletedRecordsModalIsOpen] =
    useState(false);
  const [formTitle, setFormTitle] = useState(null);
  const [tableTitle, setTableTitle] = useState("null");
  const [fields, setFields] = useState([]);
  const [cols, setCols] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchFields, setSearchFields] = useState([] as any);
  const fetchDataTable = async () => {
    setLoading(true);
    try {
      const res = await recordService.fetchOne({
        viewPath: `data-tables/${config?.data_table_id}`,
      });
      //console.log(res)
      setLoading(false);
      if (res) {
        console.log(res.cols_config);
        setFields(res.fields_config);
        setCols(res.cols_config);
        setTableTitle(res.table_title);
        setFormTitle(res.form_title);
      }
    } catch (error: any) {
      setLoading(false)
    }
  };
  useEffect(() => {
    fetchDataTable()
  }, [])
  useEffect(() => {
    const searchableFields = cols
      .filter((col: any) => col.searchable)
      .map((col: any) => col.name);
    setSearchFields(searchableFields);
  }, [cols])
  return (
    <>
      <div className="data-table-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        {loading ? (
          <div className="spinner-wrapper">
            <Spinner visible={loading} />
          </div>
        ) :
          <div className="expanded-card-body">
            <a
              className="card-btn lg"
              onClick={() => {
                setFormModalIsOpen(true);
              }}
            >
              <strong>Nuevo registro</strong>
              <div>
                <AddWrapperIcon />
                <AddIcon />
              </div>
            </a>
            <a
              className="card-btn lg"
              onClick={() => {
                setDataTableEntriesModalIsOpen(true);
              }}
            >
              <strong>Listar registros</strong>
              <div>
                <GridWrapperIcon />
                <GridIcon />
              </div>
            </a>
            <a
              className="card-btn lg"
              onClick={() => {
                setDeletedRecordsModalIsOpen(true);
              }}
            >
              <strong>Registros Eliminados</strong>
              <div>
                <DeleteWrapperIcon />
                <DeleteIcon />
              </div>
            </a>
          </div>
        }

      </div>
      <FormModal
        open={formModalIsOpen}
        setOpen={setFormModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        title={formTitle}
        fields={fields}
        mode='create'
        extraData={{
          data_table_id: config?.data_table_id
        }}
        createPath={'data-table-entries'}
      />

      <DataTableEntriesModal
        isOpen={dataTableEntriesModalIsOpen}
        title={tableTitle}
        dataTableId={config?.data_table_id}
        cols={cols}
        searchFields={searchFields}
        onClose={() => {
          setDataTableEntriesModalIsOpen(false)
        }}
      />
      <DeletedRecordsModal
        open={deletedRecordsModalIsOpen}
        setOpen={setDeletedRecordsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
      />
    </>
  );
};

export { DataTableExpandedCard };
