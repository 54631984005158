import React from "react";
import { SvgIcon } from "@mui/material";
const BoldItalicIcon = (props: any) => (
  <SvgIcon style={{ fontSize: props?.size }} {...props} viewBox="0 0 32 32">
    <path fill={props?.color} d="M5.337 5.068c1.674-0.313 3.839-0.558 6.177-0.558 2.444 0 4.261 0.313 5.832 1.256 1.569 0.803 2.723 2.304 2.723 4.295 0 2.968-2.2 4.855-4.749 5.587v0.106c2.268 0.698 3.701 2.549 3.701 5.134 0 2.513-1.361 4.365-3.211 5.587-1.991 1.327-4.959 1.885-8.871 1.885-2.653 0-4.785-0.175-5.935-0.419l4.331-22.874zM6.594 24.343c0.594 0.070 1.188 0.104 1.921 0.104 2.723 0 5.202-1.084 5.202-3.701 0-2.34-1.955-3.039-4.295-3.039h-1.573l-1.254 6.635zM8.619 14.043h1.674c2.758 0 4.644-1.186 4.644-3.177 0-1.71-1.397-2.444-3.318-2.444-0.871 0-1.569 0.070-1.955 0.175l-1.046 5.447z"></path>
    <path fill={props?.color} d="M30.673 4.685l-4.47 23.534h-5.029l4.47-23.534h5.029z"></path>
  </SvgIcon>
);

export { BoldItalicIcon };
