import React, { useEffect, useState } from 'react'
import Modal from 'components/Modal/Modal';
import './ReportModal.scss';
import { DownloadFab } from './components/DownloadFab/DownloadFab';

import configurationService from 'services/configurationService';
import recordService from 'services/recordService';
import { DynamicReportTable } from 'components/DynamicReportTable/DynamicReportTable';
import { ActiveFilters } from 'components/ActiveFilters/ActiveFilters';
import { FiltersDrawer } from 'components/drawers/FiltersDrawer/FiltersDrawer';
import { formatFilters } from 'utils/format-filters';

interface ReportModalProps {
  title: string;
  isOpen: boolean;
  listPath: string;
  reportName: string;
  onClose: () => void;
}

const ReportModal: React.FC<ReportModalProps> = ({
  isOpen,
  title,
  listPath,
  reportName,
  onClose
}) => {
  const [refreshData, setRefreshData] = useState(false);
  const [downloadingReport, setDownloadingReport] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [cols, setCols] = useState([] as any);
  const [filters, setFilters] = useState([] as any);
  // const [filtersCopy, setFiltersCopy] = useState(
  //   JSON.parse(JSON.stringify(filters))
  // );
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  //const [hasActiveFilters, setHasActiveFilters] = useState(false);
  //const [filtersDrawerIsOpen, setFiltersDrawerIsOpen] = useState(false);
  const getConfigurations = () => {
    setLoading(true);
    const configurationNames = [
      `${reportName}ReportCols`,
      `${reportName}ReportFilters`,
    ];
    configurationService
      .findByNames(configurationNames)
      .then((configurations) => {
        setLoading(false);
        configurations.forEach((configuration: any) => {
          switch (configuration.name) {
            case `${reportName}ReportCols`:
              setCols(configuration.json_schema);
              break;
            case `${reportName}ReportFilters`:
              setFilters(configuration.json_schema);

              break;

            default:
            // Manejar cualquier otro caso
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const downloadReport = async (fileType?: string) => {
    let format = null;
    let mimeType = null;
    let extension = null;
    switch (fileType) {
      case "Excel":
        format = "xls";
        mimeType = "application/vnd.ms-excel";
        extension = "xlsx";
        break;
      case "PDF":
        format = "pdf";
        mimeType = "application/pdf";
        extension = "pdf";
        break;
      case "JSON":
        format = "json";
        mimeType = "application/json";
        extension = "json";
        break;
      default:
        break;
    }
    setDownloadingReport(true);
    try {
      const formattedFilters = formatFilters(filters);
      const res = await recordService.fetchAll({
        listPath: `${listPath}?format=${format}&report_name=${reportName}&title=${title}&sort_by=${sortBy}&sort_direction=${sortDirection}&filters=${encodeURIComponent(
          JSON.stringify(formattedFilters)
        )}`,
      });

      const downloadUrl = `data:${mimeType};base64,${res}`;

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `report.${extension}`); // o el nombre del archivo que devuelve tu API
      document.body.appendChild(link);
      link.click();
      link.remove();

    } catch (error) {
      console.error("Error en la petición: ", error);

    } finally {
      setDownloadingReport(false);
    }
  };
  useEffect(() => {
    console.log(isOpen, reportName);
    if (isOpen && reportName) {
      getConfigurations();
    }
  }, [isOpen, reportName]);

  return (
    <>
      <Modal
        title={title}
        className={'report-modal-container'}
        isOpen={isOpen}
        onClose={onClose}
        hasSearchBar={true}
        filters={filters}
        onSearchChange={(searchTerm: string) => {
          console.log("searchTerm", searchTerm)
          setSearchTerm(searchTerm);
        }}
        onActiveFiltersChange={(filters) => {
          console.log("filters", filters);
          setFilters(filters);
        }}

      >

        <div>
          <DynamicReportTable
            listPath={listPath}
            cols={cols}
            searchTerm={searchTerm}
            reportName={reportName}
            filters={filters}
            onSort={(res: any) => {
              console.log('res', res);
              setSortBy(res.sortBy);
              setSortDirection(res.sortDirection);
            }}
          // forceRefresh={refreshData}
          // onChange={() => {
          //   setRefreshData(false);
          // }}
          />
        </div>
        <DownloadFab
          disabled={downloadingReport}
          onSelectItem={(fileType: string) => {
            console.log(fileType);
            downloadReport(fileType);
          }}
        />

      </Modal>

    </>

  )
}

export { ReportModal }