import { Button } from "@mui/material";
import { useState } from "react";
import { TasksBoardModal } from "../TasksBoardModal/TasksBoardModal";
import { TasksCalendarModal } from "../TasksCalendarModal/TasksCalendarModal";
import "./TasksExpandedCard.scss";
import { CheckSquareIcon } from "../../../../components/icons/CheckSquareIcon";
import { CheckSquareWrapperIcon } from "../../../../components/icons/CheckSquareWrapperIcon";
import { CalendarWrapperIcon } from "../../../../components/icons/CalendarWrapperIcon";
import { CalendarIcon } from "../../../../components/icons/CalendarIcon";
import { TaskBoardWrapperIcon } from "../../../../components/icons/TaskBoardWrapperIcon";
import { TaskBoardIcon } from "../../../../components/icons/TaskBoardIcon";
import { CompletedTasksModal } from "../CompletedTasksModal/CompletedTasksModal";
import { TagsWrapperIcon } from "../../../../components/icons/TagsWrapperIcon";
import { TagsIcon } from "../../../../components/icons/TagsIcon";
import { TagsModal } from "../../../../components/modals/TagsModal/TagsModal";
const TasksExpandedCard = (props: any) => {
  const { title, data, setRefreshOnClose } = props;
  const [calendarModalIsOpen, setCalendarModalIsOpen] = useState(false);
  const [boardModalIsOpen, setBoardModalIsOpen] = useState(false);
  const [completedTasksModalIsOpen, setCompletedTasksModalIsOpen] =
    useState(false);
  const [tagsModalIsOpen, setTagsModalIsOpen] = useState(false);
  return (
    <>
      <div className="tasks-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body">
          <a
            className="card-btn lg"
            onClick={() => {
              setCalendarModalIsOpen(true);
            }}
          >
            {/* <span>Crear</span> */}
            <strong>Calendario de tareas</strong>
            <div>
              <CalendarWrapperIcon />
              <CalendarIcon />
            </div>
          </a>
          {/* <a
            className="card-btn lg"
            onClick={() => {
              setBoardModalIsOpen(true);
            }}
          >
            <strong>Tablero de tareas</strong>
            <div>
              <TaskBoardWrapperIcon />
              <TaskBoardIcon />
            </div>
          </a> */}
          <a
            className="card-btn lg"
            onClick={() => {
              setCompletedTasksModalIsOpen(true);
            }}
          >
            {/* <span>Crear</span> */}
            <strong>Tareas finalizadas</strong>
            <div>
              <CheckSquareWrapperIcon />
              <CheckSquareIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setTagsModalIsOpen(true);
            }}
          >
            <strong>Etiquetas de tareas</strong>
            <div>
              <TagsWrapperIcon />
              <TagsIcon />
            </div>
          </a>
        </div>
      </div>
      <TasksCalendarModal
        open={calendarModalIsOpen}
        setOpen={setCalendarModalIsOpen}
        onClose={(res: any) => {
          if (res?.refresh == true) {
            setRefreshOnClose(true);
          }
        }}
        onDismiss={() => { }}
        mode="all"
      />
      <TasksBoardModal
        open={boardModalIsOpen}
        setOpen={setBoardModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
      />
      <CompletedTasksModal
        open={completedTasksModalIsOpen}
        setOpen={setCompletedTasksModalIsOpen}
        onClose={(res: any) => {
          if (res?.refresh == true) {
            setRefreshOnClose(true);
          }
        }}
        onDismiss={() => { }}
        mode="all"
      />
      <TagsModal
        open={tagsModalIsOpen}
        setOpen={setTagsModalIsOpen}
        visibleGroupName={'Tareas'}
        onClose={() => { }}
        onDismiss={() => { }}
        groupId={5}
        groupName={"tasks"}
      />
    </>
  );
};

export { TasksExpandedCard };
