import { useState, useEffect } from "react";
import { CheckIcon } from "../../icons/CheckIcon";
import { CloseIcon } from "../../icons/CloseIcon";
import { PencilIcon } from "../../icons/PencilIcon";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import { Snackbar, Alert } from "@mui/material";
import Popover from "@mui/material/Popover";
import "./PastelColorField.scss";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
const PastelColorField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    editPath,
    onChange,
    name,
    readonly,
    mode
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);

  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);

  useEffect(() => {
    if (onChange) {
      console.log("value", value);
      onChange(value);
    }
  }, [value]);
  useEffect(() => {
    console.log(defaultValue);
    setValue(defaultValue);
  }, [defaultValue]);

  const colors = [
    "#A597CC",
    "#94B7DF",
    "#83D7F1",
    "#68CDD4",
    "#4CC2B6",
    "#81CC9F",
    "#B5D687",
    "#DAD677",
    "#FFD567",
    "#FDBD68",
    "#FAA469",
    "#F59A8E",
    "#F08FB2",
  ];

  /**
   * Popover
   */
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePresentPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? name : undefined;
  const handleSelectColor = (
    event: React.MouseEvent<HTMLButtonElement>,
    color: string
  ) => {
    event.preventDefault();
    //onChange(lightenDarkenColor(color, amount));
    console.log(color);
    setValue(color);
    setAnchorEl(null);
  };

  return (
    <div className="pastel-color-field-container">
      {label && <label>{label}</label>}
      <div>
        <div>
          {readonly ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <div className="readonly-content">
              <span style={{ backgroundColor: value }}></span>
            </div>
          ) : (
            <div className="editable-content">
              <a
                style={{ backgroundColor: value }}
                onClick={(e: any) => handlePresentPopover(e)}
              ></a>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <div className="pastel-colors-popover-items">
                  {colors.map((color: string, index: number) => {
                    return (
                      <a
                        key={index}
                        onClick={(e: any) => handleSelectColor(e, color)}
                        style={{
                          backgroundColor: color,
                        }}
                      ></a>
                    );
                  })}
                </div>
              </Popover>
            </div>
          )}
        </div>

        {!readonly != true || (mode == 'edit' && editable) && (
          <FieldEditControls
            value={value}
            fieldName={name}
            editPath={editPath}
            onEditStart={() => {
              setIsEditEnabled(true);
            }}
            onEditCancel={(originalValue) => {
              setIsEditEnabled(false);
              setValue(originalValue);
            }}
            onEditEnd={(success: boolean, originalValue) => {
              setIsEditEnabled(false);
              if (!success) {
                setValue(originalValue);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export { PastelColorField };
