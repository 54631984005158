import { Button, Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { TextEditorFieldItem } from "./TextEditorFieldItem";
import { Spinner } from "components/Spinner/Spinner";
import { useTextEditorField } from "./TextEditorFieldContext";

import { GridIcon } from "../../icons/GridIcon";
import { ListIcon } from "../../icons/ListIcon";

import ImageIcon from "@mui/icons-material/Image";
import { T1Icon } from "../../icons/T1Icon";
import { T2Icon } from "../../icons/T2Icon";
import { T3Icon } from "../../icons/T3Icon";
import { TxtIcon } from "../../icons/TxtIcon";
import { ParagraphIcon } from "../../icons/ParagraphIcon";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import { CheckIcon } from "../../icons/CheckIcon";
import { CloseIcon } from "../../icons/CloseIcon";
import { PencilIcon } from "../../icons/PencilIcon";
import { ReadonlyTextEditorField } from "./ReadonlyTextEditorField/ReadonlyTextEditorField";
import { EditableTextEditorField } from "./EditableTextEditorField/EditableTextEditorField";
import { InformationMessage } from "../../InformationMessage/InformationMessage";
import { IsProcessing } from "../IsProcessing/IsProcessing";

const TextEditorField = ({
  defaultValue,
  editable,
  label,
  editPath,
  onChange,
  tableName,
  name,
  recordId,
  readonly,
}: any) => {
  //const [data, setData] = useState(defaultValue as any);
  const [enabledEdition, setEnabledEdition] = useState(false);
  const { handleAddItem, items, setItems, handleSortEnd } =
    useTextEditorField();
  const [processing, setProcessing] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("success");

  const getValueSessionStorage = (key: string) => {
    let array = sessionStorage.getItem(key);
    if (array === null) return null;
    return JSON.parse(array);
  };
  const handleSave = async () => {
    if (tableName) tableName = tableName.replace(/_/g, "-");
    const url = tableName
      ? `${urls.server}/api/${tableName}/${recordId}/${name}`
      : `${urls.server}/api/${editPath}`;
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.put(`${url}`, { data: items });
      const resData = await res.data;
      const { message } = resData;
      setAlertMsg(message.toString());
      setEnabledEdition(false);
      setProcessing(false);
      setOpenAlert(true);
      setSeverity("success");
      sessionStorage.removeItem(name);
    } catch (error: any) {
      setEnabledEdition(false);
      setProcessing(false);
      setAlertMsg(error.toString());
      setOpenAlert(true);
      setSeverity("error");
      setItems(getValueSessionStorage(name));
    }
  };
  const enableEdition = () => {
    setEnabledEdition(true);

    sessionStorage.setItem(name, JSON.stringify(defaultValue));
  };
  useEffect(() => {
    if (onChange) {
      onChange(items);
    }
  }, [items]);
  useEffect(() => {
    setItems(defaultValue || []);
  }, []);

  interface Item {
    content: string;
    fontSize: number;
    fontWeight: number;
    label: string;
    textAlign: string;
    type: string;
  }
  const isDisabled = (current: Array<Item>, prev: Array<Item>) => {
    return (
      current?.length === prev?.length &&
      current.every((value, index) => value.content === prev[index].content)
    );
  };

  return (
    <div className="text-editor-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {(recordId || editPath) && editable && (
          <div>
            {enabledEdition ? (
              <>
                {processing ? (
                  <IconButton color="primary" disabled>
                    <Spinner size="sm" />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={handleSave}
                    disabled={isDisabled(items, getValueSessionStorage(name))}
                    color="primary"
                    size="large"
                  >
                    <CheckIcon />
                  </IconButton>
                )}

                <a
                  onClick={() => {
                    setEnabledEdition(false);
                    setItems(getValueSessionStorage(name));
                  }}
                >
                  <CloseIcon />
                </a>
              </>
            ) : (
              <>
                <a onClick={enableEdition}>
                  <PencilIcon />
                </a>
              </>
            )}
          </div>
        )}
      </div>
      <div className="field-body">
        <div>
          {readonly ||
            ((recordId || editPath) && editable && !enabledEdition) ||
            ((recordId || editPath) && !editable) ? (
            <ReadonlyTextEditorField data={items} />
          ) : (
            <EditableTextEditorField
              data={items}
              onSortEnd={handleSortEnd}
              onAddItem={handleAddItem}
              onChange={(res: any) => {
                console.log(res);
                setItems(res);
              }}
            />
          )}
        </div>
      </div>
      <InformationMessage
        open={openAlert}
        message={alertMsg}
        severity={severity}
        onClose={setOpenAlert}
      />
    </div>
  );
};

export { TextEditorField };
