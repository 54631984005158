import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { BanksExpandedCard } from "cards/banks/components/BanksExpandedCard/BanksExpandedCard";
import { CustomersExpandedCard } from "cards/customers/components/CustomersExpandedCard/CustomersExpandedCard";
import { DataTableExpandedCard } from "cards/dataTable/components/DataTableExpandedCard/DataTableExpandedCard";
import { CardManagerExpandedCard } from "cards/cardManager/components/CardManagerExpandedCard/CardManagerExpandedCard";
import { ProductsExpandedCard } from "cards/products/components/ProductsExpandedCard/ProductsExpandedCard";
import { SettingsExpandedCard } from "cards/settings/components/SettingsExpandedCard/SettingsExpandedCard";
import { PurchasesExpandedCard } from "cards/purchases/components/PurchasesExpandedCard/PurchasesExpandedCard";
import { SalesExpandedCard } from "cards/sales/components/SalesExpandedCard/SalesExpandedCard";
import { SuppliersExpandedCard } from "cards/suppliers/components/SuppliersExpandedCard/SuppliersExpandedCard";
import { TasksExpandedCard } from "cards/tasks/components/TasksExpandedCard/TasksExpandedCard";
import { TaxesExpandedCard } from "cards/taxes/components/TaxesExpandedCard/TaxesExpandedCard";
import { UsersExpandedCard } from "cards/users/components/UsersExpandedCard/UsersExpandedCard";
import { IndicatorsExpandedCard } from "cards/indicators/components/IndicatorsExpandedCard/IndicatorsExpandedCard";
import { RolesExpandedCard } from "cards/roles/components/RolesExpandedCard/RolesExpandedCard";
import { IndicatorExpandedCard } from "cards/indicator/components/IndicatorExpandedCard/IndicatorExpandedCard";
import { InventoryManagerExpandedCard } from "cards/inventoryManager/components/InventoryManagerExpandedCard/InventoryManagerExpandedCard";
import { SubscriptionsExpandedCard } from "cards/subscriptions/components/SubscriptionsExpandedCard/SubscriptionsExpandedCard";
import { PayablesExpandedCard } from "cards/payables/components/PayablesExpandedCard/PayablesExpandedCard";
import { ServicesExpandedCard } from "cards/services/components/ServicesExpandedCard/ServicesExpandedCard";
import { MyTasksExpandedCard } from "cards/tasks/components/MyTasksExpandedCard/MyTasksExpandedCard";
import { MyIndicatorsExpandedCard } from "cards/indicators/components/MyIndicatorsExpandedCard/MyIndicatorsExpandedCard";
import { AddressesExpandedCard } from "cards/addresses/components/AddressesExpandedCard/AddressesExpandedCard";
import { RankingExpandedCard } from "cards/ranking/components/RankingExpandedCard/RankingExpandedCard";
import { FlowChatExpandedCard } from "cards/flowChat/components/FlowChatExpandedCard/FlowChatExpandedCard";
import { BookshelfExpandedCard } from "cards/bookshelf/components/BookshelfExpandedCard/BookshelfExpandedCard";
import { UserGroupsExpandedCard } from "cards/userGroups/components/UserGroupsExpandedCard/UserGroupsExpandedCard";
import { DataTablesExpandedCard } from "cards/dataTables/components/DataTablesExpandedCard/DataTablesExpandedCard";

import { WorkflowsExpandedCard } from "cards/workflows/components/WorkflowsExpandedCard/WorkflowsExpandedCard";
import { TutorialsExpandedCard } from "cards/tutorials/components/TutorialsExpandedCard/TutorialsExpandedCard";
import { WorkflowEntriesExpandedCard } from "cards/workflowEntries/components/WorkflowEntriesExpandedCard/WorkflowEntriesExpandedCard";
import { ScrapingExpandedCard } from "cards/scraping/components/ScrapingExpandedCard/ScrapingExpandedCard";
import { IndicatorGroupExpandedCard } from "cards/indicatorGroup/components/IndicatorGroupExpandedCard/IndicatorGroupExpandedCard";
import { IndicatorGroupsExpandedCard } from "cards/indicatorGroups/components/IndicatorGroupsExpandedCard/IndicatorGroupsExpandedCard";
import { ConfigurationsExpandedCard } from "cards/configurations/components/ConfigurationsExpandedCard/ConfigurationsExpandedCard";
import { ExitFullScreenIcon } from 'components/icons/ExitFullscreenIcon';
import "./ExpandedCardModal.scss";
import { TagsExpandedCard } from 'cards/tags/components/TagsExpandedCard/TagsExpandedCard';

interface ModalProps {
  isOpen: boolean;
  type: string;
  title: string;
  data: any;
  config: string;
  id: number;
  onClose: () => void;
  onChange?: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ExpandedCardModal: React.FC<ModalProps> = ({ isOpen, type, title, data, config, id, onClose, onChange }) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={() => {
        onClose();
        if (refreshOnClose && onChange) onChange()
      }}
      TransitionComponent={Transition}
      className={`expanded-card-modal-container`}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "50px",
          paddingBottom: "50px",
          paddingLeft: "20px",
          paddingRight: "35px",
        },
      }}
    >
      <div className='modal-window'>

        <a onClick={() => {
          onClose();
          if (refreshOnClose && onChange) onChange()
        }}>
          <ExitFullScreenIcon />
        </a>

        <div>
          <div>
            {(() => {
              switch (type) {
                case "myIndicators":
                  return (
                    <MyIndicatorsExpandedCard title={title} data={data} />
                  );
                case "myTasks":
                  return (
                    <MyTasksExpandedCard
                      title={title}
                      data={data}
                      setRefreshOnClose={setRefreshOnClose}
                    />
                  );
                case "tasks":
                  return (
                    <TasksExpandedCard
                      title={title}
                      data={data}
                      setRefreshOnClose={setRefreshOnClose}
                    />
                  );

                case "users":
                  return (
                    <UsersExpandedCard
                      title={title}
                      data={data}
                      setRefreshOnClose={setRefreshOnClose}
                    />
                  );
                case "cardManager":
                  return (
                    <CardManagerExpandedCard title={title} data={data} />
                  );
                case "dataTable":
                  return (
                    <DataTableExpandedCard
                      title={title}
                      config={config}
                      cardId={id}
                    />
                  );
                case "settings":
                  return (
                    <SettingsExpandedCard
                      title={title}
                      config={config}
                      cardId={id}
                    />
                  );
                case "tags":
                  return (
                    <TagsExpandedCard
                      title={title}
                      config={config}
                      cardId={id}
                    />
                  );
                case "sales":
                  return (
                    <SalesExpandedCard
                      title={title}
                      data={data}
                      setRefreshOnClose={setRefreshOnClose}
                    />
                  );
                case "customers":
                  return (
                    <CustomersExpandedCard
                      title={title}
                      data={data}
                      config={config}
                      setRefreshOnClose={setRefreshOnClose}
                    />
                  );
                case "purchases":
                  return (
                    <PurchasesExpandedCard
                      title={title}
                      data={data}
                      setRefreshOnClose={setRefreshOnClose}
                    />
                  );
                case "suppliers":
                  return (
                    <SuppliersExpandedCard
                      title={title}
                      data={data}
                      config={config}
                      setRefreshOnClose={setRefreshOnClose}
                    />
                  );
                case "products":
                  return (
                    <ProductsExpandedCard
                      title={title}
                      data={data}
                      config={config}
                      setRefreshOnClose={setRefreshOnClose}
                    />
                  );
                case "taxes":
                  return (
                    <TaxesExpandedCard
                      title={title}
                      data={data}
                      setRefreshOnClose={setRefreshOnClose}
                    />
                  );
                case "banks":
                  return (
                    <BanksExpandedCard
                      title={title}
                      data={data}
                      setRefreshOnClose={setRefreshOnClose}
                    />
                  );
                case "services":
                  return (
                    <ServicesExpandedCard
                      title={title}
                      data={data}
                      config={config}
                      setRefreshOnClose={setRefreshOnClose}
                    />
                  );
                case "indicators":
                  return (
                    <IndicatorsExpandedCard
                      title={title}
                      data={data}
                      config={config}
                      setRefreshOnClose={setRefreshOnClose}
                    />
                  );
                case "indicator":
                  return (
                    <IndicatorExpandedCard
                      title={title}
                      data={data}
                      config={config}
                      id={id}
                    />
                  );
                case "indicatorGroup":
                  return (
                    <IndicatorGroupExpandedCard
                      title={title}
                      data={data}
                      config={config}
                      id={id}
                    />
                  );
                case "indicatorGroups":
                  return (
                    <IndicatorGroupsExpandedCard
                      title={title}
                      data={data}
                      config={config}
                      id={id}
                    />
                  );
                case "roles":
                  return <RolesExpandedCard title={title} data={data} />;
                case "inventoryManager":
                  return (
                    <InventoryManagerExpandedCard
                      title={title}
                      data={data}
                    />
                  );
                case "subscriptions":
                  return (
                    <SubscriptionsExpandedCard title={title} data={data} />
                  );
                case "payables":
                  return (
                    <PayablesExpandedCard
                      config={config}
                      title={title}
                      data={data}
                    />
                  );
                case "addresses":
                  return (
                    <AddressesExpandedCard title={title} data={data} />
                  );
                case "ranking":
                  return <RankingExpandedCard title={title} data={data} />;
                case "flowChat":
                  return <FlowChatExpandedCard title={title} data={data} />;
                case "bookshelf":
                  return (
                    <BookshelfExpandedCard title={title} data={data} />
                  );
                case "userGroups":
                  return (
                    <UserGroupsExpandedCard
                      title={title}
                      data={data}
                      setRefreshOnClose={setRefreshOnClose}
                    />
                  );
                case "dataTables":
                  return (
                    <DataTablesExpandedCard title={title} data={data} />
                  );

                case "workflows":
                  return (
                    <WorkflowsExpandedCard
                      title={title}
                      data={data}
                      config={config}
                      setRefreshOnClose={setRefreshOnClose}
                    />
                  );
                case "tutorials":
                  return (
                    <TutorialsExpandedCard
                      title={title}
                      data={data}
                      config={config}
                    />
                  );
                case "workflowEntries":
                  return (
                    <WorkflowEntriesExpandedCard
                      title={title}
                      data={data}
                      config={config}
                    />
                  );
                case "scraping":
                  return (
                    <ScrapingExpandedCard
                      title={title}
                      data={data}
                      config={config}
                    />
                  );
                case "configurations":
                  return (
                    <ConfigurationsExpandedCard
                      title={title}
                      data={data}
                      config={config}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>

    </Dialog>
  );
};

export default ExpandedCardModal;