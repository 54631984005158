import { useState, useEffect } from "react";

import { EditableListField } from "./EditableListField/EditableListField";
import { ReadonlyListField } from "./ReadonlyListField/ReadonlyListField";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import "./ListField.scss";

const ListField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    editPath,
    onChange,
    options = [],
    name,
    recordId,
    itemType,
    itemPlaceholder,
    readonly,
    mode
  }: any = props;
  const [items, setItems] = useState((defaultValue as any) || ([] as any));
  const [isEditEnabled, setIsEditEnabled] = useState(false);




  useEffect(() => {
    if (onChange) {
      onChange(items);
    }
  }, [items]);

  return (
    <div className="list-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {editable && (
          <div>
            {!readonly != true || (mode == 'edit' && editable) && (
              <FieldEditControls
                value={items}
                fieldName={name}
                editPath={editPath}
                onEditStart={() => {
                  setIsEditEnabled(true);
                }}
                onEditCancel={(originalValue) => {
                  setIsEditEnabled(false);
                  setItems(originalValue);
                }}
                onEditEnd={(success: boolean, originalValue) => {
                  setIsEditEnabled(false);
                  if (!success) {
                    setItems(originalValue);
                  }
                }}
              />
            )}
          </div>
        )}
      </div>
      <div className="field-body">
        <div>
          {readonly ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <ReadonlyListField data={defaultValue} />
          ) : (
            <EditableListField
              defaultData={defaultValue}
              itemType={itemType}
              itemPlaceholder={itemPlaceholder}
              onChange={(value: any) => {
                setItems(value);
              }}
            />
          )}
        </div>
      </div>

    </div>
  );
};

export default ListField;
