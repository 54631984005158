import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import "./FormBuilderField.scss";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import { InputField } from "../InputField/InputField";
import ConfigurationBuilderModal from "components/modals/ConfigurationBuilderModal/ConfigurationBuilderModal";

const FormBuilderField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    editPath,
    onChange,
    name,
    readonly,
    mode
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);

  const [fieldsBuilderModalIsOpen, setFieldsBuilderModalIsOpen] =
    useState(false);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  return (
    <div className="form-builder-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {editable && (
          <div>
            {!readonly != true || (mode == 'edit' && editable) && (
              <FieldEditControls
                value={value}
                fieldName={name}
                editPath={editPath}
                onEditStart={() => {
                  setIsEditEnabled(true);
                }}
                onEditCancel={(originalValue) => {
                  setIsEditEnabled(false);
                  setValue(originalValue);
                }}
                onEditEnd={(success: boolean, originalValue) => {
                  setIsEditEnabled(false);
                  if (!success) {
                    setValue(originalValue);
                  }
                }}
              />
            )}
          </div>
        )}
      </div>
      <div className="field-body">
        <div>
          {readonly ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            // <span>{JSON.stringify(value)}</span>
            <div className="readonly-content">
              {value ? (
                <div>
                  <div>
                    <strong>Título:</strong>
                    <span>{value?.title}</span>
                  </div>
                  <div>
                    <strong>Cantidad de campos:</strong>
                    <span>{value?.fields?.length}</span>
                  </div>
                </div>
              ) : (
                <span>-</span>
              )}
            </div>
          ) : (
            <div className="editable-content">
              <div>
                {/* <strong>Título:</strong>
                    <span>{value?.title}</span> */}
                <InputField
                  type="text"
                  placeholder={"Título del formulario"}
                  defaultValue={value?.title || null}
                  onChange={(data: string) => {
                    setValue((prev: any) => {
                      const dataCopy: any = { ...prev };
                      dataCopy.title = data;
                      return dataCopy;
                    });
                  }}
                />
              </div>
              {value && (
                <div>
                  <div>
                    <strong>Cantidad de campos:</strong>
                    <span>{value?.fields?.length}</span>
                  </div>
                </div>
              )}
              <span className="btn-wrapper">
                <Button
                  onClick={() => {
                    setFieldsBuilderModalIsOpen(true);
                  }}
                >
                  {/* {!value ? "Crear" : "Editar"} formulario */}
                  Campos del formulario
                </Button>
              </span>
            </div>
          )}
        </div>
      </div>
      <ConfigurationBuilderModal
        isOpen={fieldsBuilderModalIsOpen}
        onClose={(data: any) => {
          if (data) {
            console.log(data);
            setValue((prev: any) => {
              const dataCopy: any = { ...prev };
              dataCopy.fields = data;
              return dataCopy;
            });
          }
          setFieldsBuilderModalIsOpen(false);
        }}
        data={value?.fields || []}
        type="fields"

      />
    </div>
  );
};

export default FormBuilderField;
