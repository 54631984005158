import React, { useState, useEffect } from "react";
import { Dialog, IconButton, Slide, Fab } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon } from "../../icons/CloseIcon";

import "./RecordsModal.scss";
import { DynamicTable } from "../../DynamicTable/DynamicTable";
import { Dividers } from "../../Dividers/Dividers";
import { AddIcon } from "../../icons/AddIcon";
import { FormModal } from "../FormModal/FormModal";
import { HeaderSearchbar } from "../../HeaderSearchbar/HeaderSearchbar";

import { FiltersIcon } from "components/icons/FiltersIcon";
import { FiltersDrawer } from "components/drawers/FiltersDrawer/FiltersDrawer";
import { ActiveFilters } from "components/ActiveFilters/ActiveFilters";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const RecordsModal = ({
  open,
  setOpen,
  onClose,
  title,
  tableName,
  listPath,
  createPath,
  cols,
  fields,
  filters = [],
  hiddenFilters = [],
  dividerGroups,
  formTitle,
  rowActions = [],
  tableActions = [],
  onSelectRowAction,
  allowCreate = true, // Permitir crear registros
  extraFormData,
  //readonlyForm, // Formulario de la fila en modo de solo lectura
}: any) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  const [filtersCopy, setFiltersCopy] = useState(
    JSON.parse(JSON.stringify(filters))
  );
  const [hasActiveFilters, setHasActiveFilters] = useState(false);

  const [dividerFilters, setDividerFilters] = useState([]);
  const [searchTerm, setSearchTerm] = useState("" as any);
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);

  const [filtersDrawerIsOpen, setFiltersDrawerIsOpen] = useState(false);
  useEffect(() => {
    if (!open) {
      setFiltersCopy(JSON.parse(JSON.stringify(filters)));
      setDividerFilters([]);
      setSearchTerm("");
    }
  }, [open]);
  useEffect(() => {
    // Comprobar si hay algún filtro activo
    const isActive = filtersCopy.some((filter: any) => {
      return filter.selected;
    });
    setHasActiveFilters(isActive);
    return () => {
      //setDividerFilters([]);
    };
  }, [filtersCopy]);

  const handleRemoveFilter = (index: number, updatedFilter: any) => {
    const updatedFilters = [...filtersCopy];
    updatedFilters[index] = updatedFilter;
    setFiltersCopy(updatedFilters);
  };
  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          setOpen(false);
          setHasActiveFilters(false);
          onClose({
            refresh: refreshOnClose,
          });
        }}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#f2f2f4",
          },
        }}
        className="records-modal-container"
      >
        <div className="modal-header">
          <div className="modal-title">
            <h2>{title}</h2>
          </div>
          <HeaderSearchbar
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            setRefreshData={setRefreshData}
          />
          <div className="modal-end-btns">
            <IconButton
              color="inherit"
              onClick={() => {
                setFiltersDrawerIsOpen(true);
              }}
              aria-label="filter"
            >
              <FiltersIcon />
            </IconButton>
            <IconButton
              color="inherit"
              onClick={() => {
                setOpen(false);
                onClose({
                  refresh: refreshOnClose,
                });
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="modal-body">
          {open && hasActiveFilters && (
            <ActiveFilters
              filters={filtersCopy}
              onRemoveFilter={handleRemoveFilter}
            />
          )}
          {dividerGroups && dividerGroups.length > 0 && (
            <Dividers
              tableName={tableName}
              groups={dividerGroups}
              onChange={(data: any) => {
                setDividerFilters(data);
                setHasActiveFilters(true);
              }}
            />
          )}
          {open && (
            <DynamicTable
              tableName={tableName}
              listPath={listPath}
              cols={cols}
              dividerFilters={dividerFilters}
              filters={filtersCopy}
              hiddenFilters={hiddenFilters}
              refreshData={refreshData}
              setRefreshData={setRefreshData}
              searchTerm={searchTerm}
              rowActions={rowActions}
              tableActions={tableActions}
              formTitle={formTitle}
              formFields={fields}
              onSelectRowAction={onSelectRowAction}
            //readonlyForm={readonlyForm}
            />
          )}
        </div>
        {allowCreate && (
          <>
            <Fab
              className="fab"
              color="primary"
              aria-label="add"
              onClick={() => {
                setFormModalIsOpen(true);
              }}
              sx={{
                position: "fixed",
                bottom: 16,
                right: 16,
              }}
            >
              <AddIcon />
            </Fab>
            <FormModal
              open={formModalIsOpen}
              setOpen={setFormModalIsOpen}
              onClose={(formData: any) => {
                if (formData) {
                  setRefreshData(true);
                }
              }}
              title={formTitle}
              fields={fields}
              tableName={tableName}
              createPath={createPath}
              extraData={extraFormData}
            />
          </>
        )}
      </Dialog>
      {open && (
        <FiltersDrawer
          isOpen={filtersDrawerIsOpen}
          onClose={(res: any) => {
            setFiltersDrawerIsOpen(false);
            if (res) {
              setFiltersCopy(res);
            }
          }}
          filters={filtersCopy}
        />
      )}
    </>
  );
};

export { RecordsModal };
