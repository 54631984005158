import React from "react";
import { SvgIcon } from "@mui/material";
const AlignJustifyIcon = (props: any) => (
  <SvgIcon style={{ fontSize: props?.size }} {...props} viewBox="0 0 32 32">
    <path fill={props?.color} d="M0 0h32v6h-32v-6z"></path>
    <path fill={props?.color} d="M0 8h32v6h-32v-6z"></path>
    <path fill={props?.color} d="M0 24h32v6h-32v-6z"></path>
    <path fill={props?.color} d="M0 16h32v6h-32v-6z"></path>
  </SvgIcon>
);

export { AlignJustifyIcon };
