import { useState } from "react";
import AxiosInterceptor from "../AxiosInterceptor";
import urls from "config/urls";

export const useUpdateFieldValue = ({
  tableName,
  name,
  recordId,
  editPath,
  onEdit,
  setValue,
  storageValue,
  removeValue,
}: any) => {
  const [enabledEdition, setEnabledEdition] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("success");

  const saveValue = async (value: any) => {
    if (tableName) tableName = tableName.replace(/_/g, "-");
    if (name) name = name.replace(/_/g, "-");
    const url = tableName
      ? `${urls.server}/api/${tableName}/${recordId}/${name}`
      : `${urls.server}/api/${editPath}`;
    setProcessing(true);
    try {
      const data = {
        data: value,
      };
      const res = await AxiosInterceptor.put(`${url}`, data);
      const resData = await res.data;
      const { message } = resData;
      setEnabledEdition(false);
      setProcessing(false);
      if (onEdit !== undefined) onEdit(resData);
      if (message !== undefined) setAlertMsg(message.toString());
      else setAlertMsg(`updated successfully`);
      setOpenAlert(true);
      setSeverity("success");
      removeValue();
    } catch (error: any) {
      setEnabledEdition(false);
      setProcessing(false);
      setAlertMsg(error.toString());
      setOpenAlert(true);
      setSeverity("error");
      setValue(storageValue);
    }
  };
  return {
    enabledEdition,
    processing,
    openAlert,
    alertMsg,
    severity,
    saveValue,
    setEnabledEdition,
    setOpenAlert,
    setProcessing,
  };
};
