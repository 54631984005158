import React, { useState } from "react";
import "./CustomersExpandedCard.scss";
import { getFields } from "../../utils/get-fileds";
import { getCols } from "../../utils/get-cols";
import { getDividerGroups } from "../../utils/get-divider-groups";
import { AddWrapperIcon } from "../../../../components/icons/AddWrapperIcon";
import { AddIcon } from "../../../../components/icons/AddIcon";
import { GridWrapperIcon } from "../../../../components/icons/GridWrapperIcon";
import { GridIcon } from "../../../../components/icons/GridIcon";
import { DeleteWrapperIcon } from "../../../../components/icons/DeleteWrapperIcon";
import { DeleteIcon } from "../../../../components/icons/DeleteIcon";
import { FormModal } from "../../../../components/modals/FormModal/FormModal";
import { RecordsModal } from "../../../../components/modals/RecordsModal/RecordsModal";
import { DeletedRecordsModal } from "../../../../components/modals/DeletedRecordsModal/DeletedRecordsModal";
import { TagsWrapperIcon } from "../../../../components/icons/TagsWrapperIcon";
import { TagsIcon } from "../../../../components/icons/TagsIcon";
import { TagsModal } from "../../../../components/modals/TagsModal/TagsModal";
import { getRowActions } from "../../utils/get-row-actions";

import { DataImporterModal } from "components/modals/DataImporterModal/DataImporterModal";
import { UploadWrapperIcon } from "components/icons/UploadWrapperIcon";
import { UploadIcon } from "components/icons/UploadIcon";
import { ReportModal } from "components/modals/ReportModal/ReportModal";
import { RecordsWrapperIcon } from "components/icons/RecordsWrapperIcon";
import { RecordsIcon } from "components/icons/RecordsIcon";
import { DocumentsIcon } from "components/icons/DocumentsIcon";
import { ReportWrapperIcon } from "components/icons/ReportWrapperIcon";
import { ReportIcon } from "components/icons/ReportIcon";
const CustomersExpandedCard = (props: any) => {
  const { title, config, cardId } = props;

  const [dataImporterModalIsOpen, setDataImporterModalIsOpen] = useState(false);
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  const [recordsModalIsOpen, setRecordsModalIsOpen] = useState(false);
  const [tagsModalIsOpen, setTagsModalIsOpen] = useState(false);
  const [allCustomersReportModalIsOpen, setAllCustomersReportModalIsOpen] =
    useState(false);
  const [deletedRecordsModalIsOpen, setDeletedRecordsModalIsOpen] =
    useState(false);

  const fields = getFields().customer;
  const cols = getCols().customer;
  const dividerGroups = getDividerGroups().customer;
  const rowActions = getRowActions().customer;

  return (
    <>
      <div className="customers-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body">
          <a
            className="card-btn lg"
            onClick={() => {
              setFormModalIsOpen(true);
            }}
          >
            <strong>Nuevo cliente</strong>
            <div>
              <AddWrapperIcon />
              <AddIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setRecordsModalIsOpen(true);
            }}
          >
            <strong>Listar clientes</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setTagsModalIsOpen(true);
            }}
          >
            <strong>Etiquetas de clientes</strong>
            <div>
              <TagsWrapperIcon />
              <TagsIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setDataImporterModalIsOpen(true);
            }}
          >
            <strong>Importar</strong>
            <div>
              <UploadWrapperIcon />
              <UploadIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setAllCustomersReportModalIsOpen(true);
            }}
          >
            <strong>Clientes</strong>
            <span>(Reporte)</span>
            <div>
              <ReportWrapperIcon />
              <ReportIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setDeletedRecordsModalIsOpen(true);
            }}
          >
            <strong>Clientes eliminados</strong>
            <div>
              <DeleteWrapperIcon />
              <DeleteIcon />
            </div>
          </a>
        </div>
      </div>
      <FormModal
        open={formModalIsOpen}
        setOpen={setFormModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        title={"Formulario de cliente"}
        fields={fields}
        tableName={"customers"}
      />
      <RecordsModal
        open={recordsModalIsOpen}
        setOpen={setRecordsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        title={title}
        tableName={"customers"}
        cols={cols}
        rowActions={rowActions}
        tableActions={[]}
        dividerGroups={dividerGroups}
        fields={fields}
        formTitle={"Formulario de cliente"}
      />
      <DeletedRecordsModal
        open={deletedRecordsModalIsOpen}
        setOpen={setDeletedRecordsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
      />
      <TagsModal
        open={tagsModalIsOpen}
        setOpen={setTagsModalIsOpen}
        visibleGroupName={'Clientes'}
        onClose={() => { }}
        onDismiss={() => { }}
        groupId={3}
        groupName={"customers"}
      />

      <DataImporterModal
        open={dataImporterModalIsOpen}
        setOpen={setDataImporterModalIsOpen}
        onClose={(res: any) => { }}
        //cols={config?.importFileColumns}
        configurationPath={`configurations/name/customerImportCols`}
        savePath={"customers/import"}
        title={"Importar clientes"}
      />
      <ReportModal
        isOpen={allCustomersReportModalIsOpen}
        title={"Reporte de clientes"}
        listPath={"customers/report"}
        reportName={"allCustomers"}
        onClose={() => {
          setAllCustomersReportModalIsOpen(false)
        }}
      />
    </>
  );
};

export { CustomersExpandedCard };
