import { useState, useEffect } from "react";
import AxiosInterceptor from "../AxiosInterceptor";
import urls from "config/urls";
import { formatFilters } from "utils/format-filters";
export const useLoadDataTableEntries = ({
  dataTableId,
  sortDirection,
  searchTerm,
  sortBy,
  filters,
  offset = 0,
  limit = 5,
  searchFields = [],
}: any) => {
  const [records, setRecords] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [error, setError] = useState<any>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const formattedFilters = formatFilters(filters);
  const loadRecords = async ({ offset }: any) => {
    const url = `${urls.server
      }/api/data-table-entries?data_table_id=${dataTableId}&offset=${offset}&limit=${limit}&search_term=${searchTerm}&sort_by=${sortBy}&sort_direction=${sortDirection}&search_fields=${encodeURIComponent(JSON.stringify(searchFields))}&filters=${encodeURIComponent(
        JSON.stringify(formattedFilters)
      )}`;

    try {
      if (offset) {
        setLoadingMore(true);
      } else {
        setLoading(true);
      }
      const res = await AxiosInterceptor.get(url);
      const data = await res?.data;
      if (data) {
        if (offset > 0) {
          setRecords((prev: any) => [...prev, ...data]);
        } else {
          setRecords(data);
        }
        setLoadingMore(false);
        setLoading(false);
        if (data.length < limit) setHasMore(false);
      }
    } catch (error: any) {
      setLoadingMore(false);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (offset > 0) {
      loadRecords({ offset });
    }
  }, [offset]);
  return {
    loadRecords,
    records,
    loading,
    hasMore,
    setHasMore,
    loadingMore,
  };
};
