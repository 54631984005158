import React from "react";
import { Spinner } from "components/Spinner/Spinner";
import { CheckIcon } from "components/icons/CheckIcon";
import "./IsProcessing.scss";
import { IconButton } from "@mui/material";

export const IsProcessing = (
  workflow: boolean,
  setValue: (val: any) => void,
  currentValue: any,
  storageValue: any
) => {

  const isDisabled = (currentValue: any, storageValue: any) => {
    if (typeof currentValue === "object" && typeof storageValue === "object") {
      return (
        currentValue?.length === storageValue?.length
      );
    }
    if (typeof currentValue === "object" && currentValue !== null) {
      const { name: namestored } = storageValue;
      const { name: namecurrent } = currentValue;
      return namestored === namecurrent;
    } else {
      return storageValue !== null && currentValue !== null
        ? currentValue === storageValue
        : false;
    }
  };

  return workflow ? (
    <IconButton color="primary" disabled={true}>
      <Spinner size="sm" />
    </IconButton>
  ) : (
    <IconButton
      onClick={() => setValue(currentValue)}
      disabled={isDisabled(currentValue, storageValue)}
      color="primary"
      size="large"
    >
      <CheckIcon />
    </IconButton>
  );
};
