import React from "react";
import { AnimatedNumber } from "../../AnimatedNumber/AnimatedNumber";

import "./MiniFilledShapeChart.scss";
import urls from "config/urls";
const MiniFilledShapeChart = (props: any) => {
  const { data, decimalPrecision } = props;
  const squareSize = data?.length > 2 ? 140 / data.length : 53;
  return (
    <div className="mini-filled-shape-chart-container">
      {/* {JSON.stringify(data)} */}
      {data?.map((item: any, index: number) => {
        return (
          <div key={index}>
            <div
              style={{
                width: squareSize + "px",
                height: squareSize + "px",
              }}
            >
              <div
                style={{
                  width: squareSize + "px",
                  height: squareSize + "px",
                }}
              >
                <img style={{
                  // width: squareSize + "px",
                  // height: squareSize + "px",
                }} src={urls.server + item.shape_file} alt="" />
              </div>
              <div
                style={{
                  height: squareSize - 2 + "px",
                  width: squareSize - 2 + "px",
                }}
              ></div>
              <div
                style={{
                  backgroundColor: item.color,
                  height: item.height - 2 + "%",
                  width: squareSize - 2 + "px",
                }}
              ></div>
            </div>
            <div>
              <div>
                <AnimatedNumber
                  value={item.value}
                  duration="500"
                  color={item?.color}
                  fontSize={data?.length > 2 ? ".6em" : ".7em"}
                  decimalPrecision={decimalPrecision}
                  type="decimal"
                />
              </div>
              <span style={{ fontSize: ".7em" }}>{item.label}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { MiniFilledShapeChart };
