import React from "react";
import { SvgIcon } from "@mui/material";
const AlignLeftIcon = (props: any) => (
  <SvgIcon style={{ fontSize: props?.size }} {...props} viewBox="0 0 32 32">
    <path fill={props?.color} d="M22 0h-22v6h22v-6z"></path>
    <path fill={props?.color} d="M30 8h-30v6h30v-6z"></path>
    <path fill={props?.color} d="M26 16h-26v6h26v-6z"></path>
    <path fill={props?.color} d="M32 24h-32v6h32v-6z"></path>
  </SvgIcon>
);

export { AlignLeftIcon };
