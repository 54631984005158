import { useState, useEffect, Fragment } from "react";
import Fab from "@mui/material/Fab";

import { FormModal } from "../../../../components/modals/FormModal/FormModal";

import urls from "config/urls";
import AxiosInterceptor from "../../../../AxiosInterceptor";

import { LoadingButton } from "@mui/lab";
import { PlusIcon } from "../../../../components/icons/PlusIcon";
import { AddIcon } from "../../../../components/icons/AddIcon";
import { Spinner } from "../../../../components/Spinner/Spinner";
import { HeaderSearchbar } from "../../../../components/HeaderSearchbar/HeaderSearchbar";
import "./IndicatorsExpandedCard.scss";
import { IndicatorItem } from "../IndicatorItem/IndicatorItem";
import { getFields } from "../../utils/get-fileds";
import { IndicatorIcon } from "../../../../components/icons/IndicatorIcon";

const IndicatorsExpandedCard = (props: any) => {
  const { title, data, setRefreshOnClose } = props;

  const [openFormModal, setOpenFormModal] = useState(false);

  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [records, setRecords] = useState([] as any);
  const [hasMore, setHasMore] = useState(true);
  const fields: any = getFields().indicator;
  const loadRecords = async (offset: number) => {
    if (offset) {
      setLoadingMore(true);
    } else {
      setLoading(true);
    }

    const res = await AxiosInterceptor.get(
      `${urls.server
      }/api/indicators?offset=${offset}&limit=${limit}&sort_by=${sortBy}&sort_direction=${sortDirection}&search_term=${searchTerm}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    );
    const data = await res.data;
    if (offset > 0) {
      setRecords((prev: any) => [...prev, ...data]);
    } else {
      setRecords(data);
    }

    setLoadingMore(false);
    setLoading(false);
    if (data.length < limit) setHasMore(false);
  };
  const getMore = () => {
    setOffset((prev: number) => prev + limit);
  };
  useEffect(() => {
    loadRecords(0);
  }, [searchTerm]);
  useEffect(() => {
    if (offset > 0) loadRecords(offset);
  }, [offset]);
  return (
    <div className="indicators-expanded-card-container">
      <div className="expanded-card-header">
        <h2 className="expanded-card-title">{title}</h2>
        <HeaderSearchbar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </div>

      {loading && (
        <div className="spinner-wrapper">
          <Spinner visible={loading} />
        </div>
      )}
      {!loading && records.length == 0 && (
        <div className="no-content">
          <IndicatorIcon />
          <p>No hay Indicadores cargados</p>
        </div>
      )}
      {!loading && records.length > 0 && (
        <>
          <div className="cards-list">
            {records.map((item: any, index: number) => {
              return (
                <IndicatorItem
                  key={index}
                  id={index}
                  data={item}
                  onDelete={() => {
                    loadRecords(0);
                  }}
                  onChange={() => {
                    loadRecords(0);
                    setRefreshOnClose(true);
                  }}
                />
              );
            })}
          </div>
          {hasMore && (
            <div className="loading-button-wrapper">
              <LoadingButton
                onClick={getMore}
                loading={loadingMore}
                loadingPosition="start"
                startIcon={<PlusIcon />}
              >
                Más registros
              </LoadingButton>
            </div>
          )}
        </>
      )}
      {/* Los indicadores se agregan cuando creo una tarjeta */}
      <Fab
        className="fab"
        aria-label="add"
        onClick={() => {
          setOpenFormModal(true);
        }}
      >
        <AddIcon />
      </Fab>
      <FormModal
        open={openFormModal}
        setOpen={setOpenFormModal}
        onClose={(res: any) => {
          if (res) {
            setRefreshOnClose(true);
            loadRecords(0);
          }
        }}
        onDismiss={() => { }}
        title="Formulario del indicador"
        fields={fields}
        tableName="indicators"
        mode='create'
      />
    </div>
  );
};

export { IndicatorsExpandedCard };
