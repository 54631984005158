import { Dispatch, SetStateAction } from "react";

type UseCancelEdit = {
  handleClose: (storageValue: any, isEditingMode: boolean) => void;
};

type HookParams = {
  setValue: Dispatch<SetStateAction<any>>;
  setEnabled: Dispatch<SetStateAction<boolean>>;
};
export const useCancelEdit = ({
  setValue,
  setEnabled,
}: HookParams): UseCancelEdit => {
  const handleClose = (storageValue: any, isEditingMode: boolean) => {
    setEnabled(isEditingMode);
    if (storageValue !== "") setValue(storageValue);
  };

  return { handleClose };
};
