import React from "react";
import "./TagsCard.scss";
const TagsCard = (props: any) => {
  const { title } = props;
  return (
    <div className="tags-card-container">
      <h2 className="card-title">{title}</h2>
      <div>
        <svg viewBox="0 0 92 92">
          <path
            className="tags"
            d="m 6.714707,12.619899 c -0.9203594,0.0077 -1.7568718,0.419794 -2.3811129,1.044035 -0.6888178,0.688818 -1.1192248,1.635875 -1.0331225,2.669102 L 4.936451,41.561071 c 0.086102,0.861022 0.4304069,1.635918 1.0331225,2.238634 L 38.171994,75.399268 c 0.688817,0.688819 1.549799,1.033325 2.410821,1.033325 0.861021,0 1.722003,-0.344506 2.410821,-1.033325 L 67.016159,51.72112 c 0.688818,-0.602715 1.033325,-1.463698 1.033325,-2.410821 0,-0.947125 -0.344507,-1.808107 -1.033325,-2.410822 L 34.900035,15.21382 C 34.29732,14.611105 33.522423,14.266589 32.6614,14.266589 L 7.002898,12.630609 c -0.096865,-0.0081 -0.1929814,-0.01151 -0.288191,-0.01071 z m 47.502975,2.120205 c -0.774921,0 -1.549826,0.301468 -2.152542,0.904184 -1.205431,1.119328 -1.205431,3.013556 0,4.218987 L 82.114818,49.396392 60.244835,70.835907 c -1.205431,1.119329 -1.205431,3.013557 0,4.218988 0.602715,0.602715 1.377621,0.860935 2.152541,0.860935 0.774921,0 1.549825,-0.25822 2.152541,-0.860935 L 88.57244,51.462839 c 0.516613,-0.602715 0.861137,-1.377621 0.861137,-2.15254 0,-0.774921 -0.344524,-1.549826 -0.861137,-2.152542 L 56.370222,15.644288 c -0.602715,-0.602716 -1.377621,-0.904184 -2.15254,-0.904184 z M 20.794235,24.62631 c 2.522933,5e-5 4.568162,2.045279 4.568212,4.568212 6.2e-5,2.523012 -2.0452,4.568364 -4.568212,4.568414 -2.523091,6.2e-5 -4.568477,-2.045323 -4.568415,-4.568414 5e-5,-2.523012 2.045403,-4.568274 4.568415,-4.568212 z"
          />
        </svg>
      </div>
    </div>
  );
};

export { TagsCard };
