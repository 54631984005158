import React, { useState, useEffect } from "react";
import "./ProductVariantsField.scss";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import { ProductAttributeValuesSelector } from "./ProductAttributeValuesSelector/ProductAttributeValuesSelector";
import { ProductVariantList } from "./ProductVariantList/ProductVariantList";
import { EditableProductVariantsField } from "./EditableProductVariantsField/EditableProductVariantsField";
import { CheckIcon } from "components/icons/CheckIcon";
import { CloseIcon } from "components/icons/CloseIcon";
import { PencilIcon } from "components/icons/PencilIcon";
import { ReadonlyProductVariantsField } from "./ReadonlyProductVariantsField/ReadonlyProductVariantsField";
const ProductVariantsField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    onChange,
    readonly,
    recordId,
    editPath,
    formData,
  } = props;
  const [enabledEdition, setEnabledEdition] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [attributesWithValues, setAttributesWithValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const handleSave = () => {
    setEnabledEdition(false);
  };
  const enableEdition = () => {
    setEnabledEdition(true);
  };
  const loadAttributesWithValues = async () => {
    setLoading(true);
    try {
      // Guardar los datos en la base de datos del tenant
      const url = `${urls.server}/api/product-attributes/values`;
      const res = await AxiosInterceptor.get(url);
      const resData = await res.data;
      setAttributesWithValues(resData);
      setLoading(false);
    } catch (error: any) { }
  };
  useEffect(() => {
    console.log(defaultValue);
    loadAttributesWithValues();
  }, []);

  return (
    <div className="product-variants-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {(recordId || editPath) && editable && (
          <div>
            {!readonly && (recordId || editPath) && editable && (
              <>
                {enabledEdition ? (
                  <>
                    <a onClick={handleSave}>
                      <CheckIcon />
                    </a>
                    <a onClick={() => setEnabledEdition(false)}>
                      <CloseIcon />
                    </a>
                  </>
                ) : (
                  <>
                    <a onClick={enableEdition}>
                      <PencilIcon />
                    </a>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>

      <div className="field-body">
        {readonly ||
          ((recordId || editPath) && editable && !enabledEdition) ||
          ((recordId || editPath) && !editable) ? (
          <div className="readonly-content">
            <ReadonlyProductVariantsField data={value} formData={formData} />
          </div>
        ) : (
          <div className="editable-content">
            <EditableProductVariantsField
              attributesWithValues={attributesWithValues}
              setAttributesWithValues={setAttributesWithValues}
              data={value}
              setData={setValue}
              onChange={onChange}
              formData={formData}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { ProductVariantsField };
