import { useState, useEffect, Fragment } from "react";
import Icon from "../../../../components/Icon/Icon";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import {
  IconButton,
  Popover,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import "./CardListItem.scss";
import { CardConfigModal } from "../CardConfigModal/CardConfigModal";
import { PlayPauseCell } from "../../../../components/cells/PlayPauseCell/PlayPauseCell";
import { UsersCell } from "components/cells/UsersCell/UsersCell";
import { VinculationModal } from "components/modals/VinculationModal/VinculationModal";
import urls from "config/urls";

const CardListItem = ({ data, id, onDelete, onChange }: any) => {
  const [openVinculationModal, setOpenVinculationModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openCardConfigModal, setOpenCardConfigModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = Boolean(anchorEl);
  const presentPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closePopover = () => {
    setAnchorEl(null);
  };
  const closeAlert = () => {
    setOpenAlert(false);
  };
  const removeCard = async () => {
    setProcessing(true);
    const res = await AxiosInterceptor.delete(
      `${urls.server}/api/cards/${data?.id}`
    );
    console.log(res);
    onDelete(true);
    setProcessing(false);
    closeAlert();
    closePopover();
  };
  return (
    <div className="card-list-item-container">
      <div>
        <div>
          <Icon name={data?.icon} />
        </div>

        <div>
          <span>{data?.visible_id}</span>
          <strong>{data?.visible_name}</strong>
          <div>{data?.visible_type}</div>
        </div>
      </div>

      <div>
        <div>
          <Button
            onClick={() => {
              setOpenVinculationModal(true);
            }}
          >

            Tarjetas hijas

            {data?.child_cards.length > 0 ? <><strong style={{ fontWeight: '900', paddingLeft: 3 }}>({data?.child_cards.length})</strong></> : null}
          </Button>
        </div>
        <UsersCell
          data={data.users}
          modalSubtitle={"Tarjeta:" + " " + data?.visible_name}
          tableName={"cards"}
          recordId={data.id}
          onChange={(val: any) => {
            if (val) {
              onChange();
            }
          }}
        />

        <div style={{ marginLeft: "10px" }}>
          <PlayPauseCell
            editPath={`cards/${data?.id}/is-active`}
            value={data?.is_active}
          />
        </div>
        <IconButton aria-label="delete" onClick={presentPopover}>
          <MoreVertIcon />
        </IconButton>
        <Popover
          id={"popover_" + id}
          open={openPopover}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className="card-popover-items">
            <a onClick={() => setOpenAlert(true)}>Eliminar</a>
            {data.is_configurable ? (
              <a
                onClick={() => {
                  setOpenCardConfigModal(true);
                  closePopover();
                }}
              >
                Configuración
              </a>
            ) : (
              <></>
            )}
          </div>
        </Popover>
        <CardConfigModal
          open={openCardConfigModal}
          setOpen={setOpenCardConfigModal}
          onDismiss={(data: any) => {
            if (data?.refresh) {
              onChange();
            }
          }}
          cardVisibleName={data.visible_name}
          cardId={data.id}
          config={data.config}
        />
        <Dialog
          open={openAlert}
          onClose={closeAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Quitar"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Desea eliminar la tarjeta <b>{data?.title}</b>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlert} autoFocus>
              Cancelar
            </Button>
            <Button onClick={removeCard}>Aceptar</Button>
          </DialogActions>
        </Dialog>
        <VinculationModal
          open={openVinculationModal}
          setOpen={setOpenVinculationModal}
          onClose={(res: any) => {
            if (res) {
            }
          }}
          title="Vincular tarjetas"
          vinculationPath={`cards/${data.id}/child-cards`}
          searchPath={"cards"}
          primaryKey={"visible_name"}
          data={data.child_cards}
        />
      </div>
    </div>
  );
};

export { CardListItem };
