import { useState, useEffect } from "react";
import "./ChecklistField.scss";
import { ReadonlyChecklistField } from "./ReadonlyChecklistField/ReadonlyChecklistField";
import { EditableChecklistField } from "./EditableChecklistField/EditableChecklistField";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
const ChecklistField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    editPath,
    onChange,
    name,
    readonly,
    mode
  }: any = props;
  const [data, setData] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);



  useEffect(() => {
    if (onChange) {
      onChange(data);
    }
  }, [data]);

  return (
    <div className="checklist-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {editable && (
          <div>
            {!readonly != true || (mode == 'edit' && editable) && (
              <FieldEditControls
                value={data}
                fieldName={name}
                editPath={editPath}
                onEditStart={() => {
                  setIsEditEnabled(true);
                }}
                onEditCancel={(originalValue) => {
                  setIsEditEnabled(false);
                  setData(originalValue);
                }}
                onEditEnd={(success: boolean, originalValue) => {
                  setIsEditEnabled(false);
                  if (!success) {
                    setData(originalValue);
                  }
                }}
              />
            )}</div>
        )}
      </div>
      <div className="field-body">
        <div>
          {readonly ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <ReadonlyChecklistField data={defaultValue} />
          ) : (
            <EditableChecklistField
              data={defaultValue}
              onChange={(res: any) => {
                console.log(res);
                setData(res);
              }}
            />
          )}
        </div>
      </div>

    </div>
  );
};

export { ChecklistField };
