import React, { useState, useEffect } from "react";
import "./SingleBreakdownIndicatorCardBody.scss";
import { AnimatedNumber } from "components/AnimatedNumber/AnimatedNumber";
import { MiniMultiLineChart } from "components/miniCharts/MiniMultiLineChart/MiniMultiLineChart";
import { transformData } from "cards/indicator/utils/transform-data";
import { MiniFilledShapeChart } from "components/miniCharts/MiniFilledShapeChart/MiniFilledShapeChart";
import { MiniGroupedColumnChart } from "components/miniCharts/MiniGroupedColumnChart/MiniGroupedColumnChart";
import { MiniStackedColumnChart } from "components/miniCharts/MiniStackedColumnChart/MiniStackedColumnChart";
import { MiniBreakdownChart } from "components/miniCharts/MiniBreakdownChart/MiniBreakdownChart";
import { MiniPieChart } from "components/miniCharts/MiniPieChart/MiniPieChart";
const SingleBreakdownIndicatorCardBody = (props: any) => {
  const { data = [], config } = props;
  const [currentIndex, setCurrentIndex] = useState(data?.length - 1);
  const [currentItem, setCurrentItem] = useState(data[data?.length - 1]);
  const [currentSubIndex, setCurrentSubIndex] = useState(0);
  const [currentSubItem, setCurrentSubItem] = useState({} as any);
  // const [currentSubItem, setCurrentsubItem] = useState(
  //   data[data?.length - 1].data[0]
  // );
  const transformedData =
    data?.length > 10 ? transformData(data?.slice(-10)) : data;
  useEffect(() => {
    setTimeout(() => {
      setCurrentItem(data[data?.length - 1]);

      if (
        config.chartType === "breakdownChart" ||
        config.chartType === "pieChart"
      ) {
        //if (data.length > 0) {
        const sortedData = [...data[data?.length - 1]?.data].sort(
          (a: any, b: any) => b.value - a.value
        );
        setCurrentSubItem(sortedData[0]);
        //}
      }
    }, 100);
  }, []);
  useEffect(() => {
    const newData = data?.slice(-10);
    setCurrentItem(newData[currentIndex]);
  }, [currentIndex]);
  useEffect(() => {
    if (
      config.chartType === "breakdownChart" ||
      config.chartType === "pieChart"
    ) {
      //if (data.length > 0) {
      const sortedData = [...data[data?.length - 1]?.data].sort(
        (a: any, b: any) => b.value - a.value
      );
      setCurrentSubItem(sortedData[currentSubIndex]);
      //}
    }
  }, [currentSubIndex]);
  return (
    <div className="single-breakdown-indicator-card-body-container">
      {data.length > 0 ? (
        <>
          <div className="current-item">
            <span>{currentItem?.visible_period}</span>
            {config.chartType != "filledShapeChart" &&
              config.chartType != "breakdownChart" &&
              config.chartType != "pieChart" && (
                <div>
                  {currentItem?.data?.map((item: any, index: number) => {
                    return (
                      <span key={index}>
                        <span>{item.label} </span>
                        <AnimatedNumber
                          value={item?.value}
                          duration="500"
                          color={item?.color}
                          decimalPrecision={config.decimalPrecision}
                          type={"decimal"}
                          //fontSize={"13px"}
                          fontSize={
                            currentSubItem?.value > 9999 ? "13px" : "16px"
                          }
                        />
                      </span>
                    );
                  })}
                </div>
              )}
          </div>

          {config.chartType === "breakdownChart" && (
            <div className="current-sub-item">
              <span
                style={{
                  color: currentSubItem?.color,
                  fontSize: ".8em",
                  lineHeight: 1.1,
                }}
              >
                {currentSubItem.label}
              </span>
              <AnimatedNumber
                value={currentSubItem?.value}
                duration="500"
                color={currentSubItem?.color}
                fontWeight={900}
                fontSize={currentSubItem?.value > 9999 ? "12px" : "15px"}
              />
            </div>
          )}

          <div className="chart-wrapper">
            {(() => {
              switch (config.chartType) {
                case "multiLineChart":
                  return (
                    <MiniMultiLineChart
                      datasets={transformedData?.datasets}
                      height={50}
                      periods={transformedData?.periods}
                      onSelectPoints={(index: number) => {
                        console.log(index);
                        setCurrentIndex(index);
                      }}
                    />
                  );
                case "filledShapeChart":
                  return <MiniFilledShapeChart data={currentItem?.data} decimalPrecision={config?.decimalPrecision} />;
                case "groupedColumnChart":
                  return (
                    <MiniGroupedColumnChart
                      data={data?.slice(-10)}
                      onSelectGroup={(index: number) => {
                        console.log(index);
                        setCurrentIndex(index);
                      }}
                    />
                  );
                case "stackedColumnChart":
                  return (
                    <MiniStackedColumnChart
                      data={data?.slice(-10)}
                      onSelectGroup={(index: number) => {
                        console.log(index);
                        setCurrentIndex(index);
                      }}
                    />
                  );
                case "breakdownChart":
                  return (
                    <MiniBreakdownChart
                      data={currentItem?.data}
                      onSelectItem={(index: number) => {
                        console.log(index);
                        setCurrentSubIndex(index);
                      }}
                    />
                  );
                case "pieChart":
                  return (
                    <div className="pie-chart-wrapper">
                      <MiniPieChart
                        data={currentItem?.data}
                        height={85}
                        onSelectItem={(index: number) => {
                          console.log(index);
                          setCurrentSubIndex(index);
                        }}
                      />
                      <div className="pie-current-sub-item">
                        <AnimatedNumber
                          value={currentSubItem?.value}
                          duration="500"
                          color={currentSubItem?.color}
                          fontWeight={900}
                          fontSize={
                            currentSubItem?.value > 9999 ? "13px" : "16px"
                          }
                        />
                        <span
                          style={{
                            color: currentSubItem?.color,
                            fontSize: 13,
                            lineHeight: 1.2,
                            //display: "block",
                          }}
                        >
                          {currentSubItem.label}
                        </span>
                      </div>
                    </div>
                  );
              }
            })()}
          </div>
        </>
      ) : (
        <div className="no-data">- {config?.measurementUnit} </div>
      )}
    </div>
  );
};

export { SingleBreakdownIndicatorCardBody };
