import React, { useEffect, useState, Fragment } from "react";
import { Dialog, IconButton, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon } from "../../../../components/icons/CloseIcon";
import { PencilIcon } from "../../../../components/icons/PencilIcon";
import "./CardConfigModal.scss";

import { InputField } from "../../../../components/fields/InputField/InputField";
import { ColorField } from "../../../../components/fields/ColorField/ColorField";
import { GridField } from "components/fields/GridField/GridField";
//import * as cardConfigs from "../../../../config/cards";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const CardConfigModal = ({
  open,
  setOpen,
  onDismiss,
  config,
  cardVisibleName,
  cardId,
}: any) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  //const config = {};
  const [openFormBuilderModal, setOpenFormBuilderModal] = useState(false);
  const [openTableBuilderModal, setOpenTableBuilderModal] = useState(false);
  const [dividerBuilderModalIsOpen, setDividerBuilderModalIsOpen] =
    useState(false);

  useEffect(() => { }, []);
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        setOpen(false);
        onDismiss({
          refresh: refreshOnClose,
        });
      }}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: "#f2f2f4",
        },
      }}
      className="card-config-modal-container"
    >
      <div className="modal-header">
        <div className="modal-title">
          <h2>Configuración</h2>
          <span>
            <b>Tarjeta:</b> {cardVisibleName}
          </span>
        </div>
        <div className="modal-end-btns">
          <IconButton
            color="inherit"
            onClick={() => {
              setOpen(false);
              onDismiss({
                refresh: refreshOnClose,
              });
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="modal-body">
        <div className="config-items">
          {config instanceof Object && (
            <>
              {Object.entries(config).map(([key, value], index) => {
                return (
                  <Fragment key={index}>
                    {(() => {
                      switch (key) {
                        case "title":
                          return (
                            <InputField
                              id={key}
                              type="text"
                              label={"Título"}
                              onChange={(value: any) => { }}
                              defaultValue={value}
                              editable={true}
                              recordId={cardId}
                              editPath={`cards/${cardId}/title`}
                            />
                          );
                        case "color":
                          return (
                            <ColorField
                              label={"Color"}
                              onChange={(value: any) => { }}
                              defaultValue={value}
                              editable={true}
                              recordId={cardId}
                              editPath={`cards/${cardId}/color`}
                            />
                          );
                        case "form":
                          return (
                            <a
                              className="form-config-item"
                              onClick={() => {
                                setOpenFormBuilderModal(true);
                              }}
                            >
                              <span>Formulario</span>
                              <div>
                                {config?.form?.fields?.length || "0"} campos
                              </div>
                              <PencilIcon />
                            </a>
                          );
                        case "table":
                          return (
                            <a
                              className="table-config-item"
                              onClick={() => {
                                setOpenTableBuilderModal(true);
                              }}
                            >
                              <span>Tabla</span>
                              <div>
                                {config?.table?.cols?.length || "0"} columnas
                              </div>
                              <PencilIcon />
                            </a>
                          );
                        case "dividerGroups":
                          return (
                            <a
                              className="divider-groups-config-item"
                              onClick={() => {
                                setDividerBuilderModalIsOpen(true);
                              }}
                            >
                              <span>Grupos de pestañas</span>
                              <div>
                                {config?.dividerGroups?.length || "0"} grupos
                              </div>
                              <PencilIcon />
                            </a>
                          );
                        case "importFileColumns":
                          return (
                            <GridField
                              label={"Columnas del archivo de importación"}
                              onChange={(value: any) => { }}
                              defaultValue={value}
                              editable={true}
                              recordId={cardId}
                              editPath={`cards/${cardId}/import-file-columns`}
                              cols={[
                                {
                                  type: "text",
                                  name: "title",
                                  visibleName: "Título",
                                  size: 6,
                                },
                                {
                                  type: "text",
                                  name: "type",
                                  visibleName: "Tipo de dato",
                                  size: 6,
                                },
                              ]}
                            />
                          );
                        case "exportFileColumns":
                          return (
                            <GridField
                              label={"Columnas del archivo de exportación"}
                              onChange={(value: any) => { }}
                              defaultValue={value}
                              editable={true}
                              recordId={cardId}
                              editPath={`cards/${cardId}/export-file-columns`}
                              cols={[
                                {
                                  type: "text",
                                  name: "title",
                                  visibleName: "Título",
                                  size: 6,
                                },
                                {
                                  type: "text",
                                  name: "type",
                                  visibleName: "Tipo de dato",
                                  size: 6,
                                },
                              ]}
                            />
                          );
                      }
                    })()}
                  </Fragment>
                );
              })}
            </>
          )}
        </div>
      </div>
      {/* <FormBuilderModal
        open={openFormBuilderModal}
        setOpen={setOpenFormBuilderModal}
        config={config?.form || {}}
        savePath={`board-cards/${cardId}/update-form`}
        onClose={(data: any) => {
          console.log(data);
          if (data.refresh) {
          }
        }}
      />
      <TableBuilderModal
        open={openTableBuilderModal}
        setOpen={setOpenTableBuilderModal}
        config={config?.table || {}}
        savePath={`board-cards/${cardId}/update-table`}
        onDismiss={(data: any) => {
          console.log(data);
          if (data.refresh) {
          }
        }}
      />
      <DividerBuilderModal
        open={dividerBuilderModalIsOpen}
        setOpen={setDividerBuilderModalIsOpen}
        config={config?.dividerGroups || []}
        savePath={`board-cards/${cardId}/update-divider-groups`}
        onDismiss={(data: any) => {
          console.log(data);
          if (data.refresh) {
          }
        }}
      /> */}
    </Dialog>
  );
};

export { CardConfigModal };
