export const getFields = () => {
  const data: any = {
    indicator: [
      {
        type: "text",
        visibleName: "Nombre del indicador",
        name: "visible_name",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      // {
      //   type: "periodicity",
      //   visibleName: "Periodicidad",
      //   name: "periodicity",
      //   description: null,
      //   size: 12,
      //   editable: null,
      //   editableIf: null,
      //   validations: {},
      // },
      {
        type: "radio",
        visibleName: "Periodicidad",
        name: "periodicity",
        description: null,
        //options: ["Simple", "singleBreakdown", "Múltiples desgloses"],
        options: [
          {
            name: "daily",
            visibleName: "Diaria",
          },
          {
            name: "weekly",
            visibleName: "Semanal",
          },
          {
            name: "monthly",
            visibleName: "Mensual",
          },
          {
            name: "bimonthly",
            visibleName: "Bimestral",
          },
          {
            name: "quarterly",
            visibleName: "Trimestral",
          },
          {
            name: "four-monthly",
            visibleName: "Cuatrimestral",
          },
          {
            name: "semiannually",
            visibleName: "Semestral",
          },
          {
            name: "annually",
            visibleName: "Anual",
          },
          {
            name: "biennially",
            visibleName: "Bianual",
          },
        ],
        defaultValue: 'monthly',
        size: 12,
        showIf: null,
        editable: false,
        editableIf: null,
        validations: {},
      },
      {
        type: "tags",
        visibleName: "Etiquetas",
        name: "tags",
        description: null,
        size: 12,
        tagGroup: {
          id: 7,
        },
        editable: true,
        editableIf: null,
        validations: {},
      },

      {
        type: "text",
        visibleName: "Código",
        name: "code",
        description: "EJ: IND001, IND002, etc.",
        size: 4,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "text",
        visibleName: "Unid. de nedida",
        name: "measurement_unit",
        description: "Ej: $, Mil. $, US$, Mill. US$, etc.",
        size: 4,
        editable: true,
        editableIf: null,
        validations: {},
      },

      {
        type: "integer",
        visibleName: "Precisión decimal",
        name: "decimal_precision",
        description: null,
        size: 4,
        showIf: null,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "list",
        visibleName: "Variables que intervienen",
        name: "variables",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        //defaultValue: ["yyy", "oooo"],
        //itemPlaceholder: "mi@email.com",
        validations: {},
      },
      {
        type: "longText",
        visibleName: "Limitaciones del indicador",
        name: "limitations",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "longText",
        visibleName: "Metodología de cálculo",
        name: "calculation_methodology",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "longText",
        visibleName: "Objetivo",
        name: "objective",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "radio",
        visibleName: "Tipo de indicador",
        name: "type",
        description: null,
        //options: ["Simple", "singleBreakdown", "Múltiples desgloses"],
        options: [
          { name: "simple", visibleName: "Simple" },
          { name: "singleBreakdown", visibleName: "Único desglose" },
          { name: "multiBreakdown", visibleName: "Múltiples desgloses" },
        ],
        size: 7,
        showIf: null,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "radio",
        visibleName: "Tipo de impacto",
        name: "impact_type",
        description: null,
        //options: ["Simple", "singleBreakdown", "Múltiples desgloses"],
        options: [
          { name: "positive", visibleName: "Positivo" },
          { name: "negative", visibleName: "Negativo" },
        ],
        size: 5,
        showIf: null,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "picker",
        visibleName: "Tipo de gráfico",
        name: "chart_type",
        options: [
          {
            label: "Gráfico de lineas",
            value: "lineChart",
            icon: "lineChartCard",
          },
          {
            label: "Gráfico de columnas",
            value: "columnChart",
            icon: "columnChartCard",
          },
        ],
        description: null,
        size: 12,
        showIf: "type == 'simple'",
        editable: true,
        editableIf: null,
        defaultValue: "columnChart",
        validations: {
          maxItems: 1,
        },
      },
      {
        type: "checkbox",
        visibleName: "Calcular variación",
        name: "calculate_variation",
        description: "Variación porcentual de los totates del indicador",
        size: 6,
        showIf: "type == 'simple'",
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "pastelColor",
        visibleName: "Color",
        name: "color",
        description: null,
        size: 6,
        showIf: "type == 'simple'",
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "picker",
        visibleName: "Tipo de gráfico",
        name: "chart_type",
        options: [
          {
            label: "Gráfico de lineas multiples",
            value: "multiLineChart",
            icon: "multiLineChartCard",
          },
          {
            label: "Gráfico de columnas apiladas",
            value: "stackedColumnChart",
            icon: "stackedColumnChartCard",
          },
          {
            label: "Gráfico de columnas agrupadas",
            value: "groupedColumnChart",
            icon: "columnChartCard",
          },
          {
            label: "Gráfico de torta",
            value: "pieChart",
            icon: "pieChartCard",
          },
          {
            label: "Gráfico de desglose",
            value: "breakdownChart",
            icon: "breakdownChartCard",
          },
          {
            label: "Gráfico de relleno",
            value: "filledShapeChart",
            icon: "filledShapeChartCard",
          },
        ],
        description: null,
        size: 12,
        showIf: "type == 'singleBreakdown'",
        editable: true,
        editableIf: null,
        defaultValue: "filledShapeChart",
        validations: {
          maxItems: 1,
        },
      },
      {
        type: "text",
        visibleName: "Nombre del desglose",
        name: "breakdown_name",
        description: "Ej: Por sexos, Por provincias, etc...",
        showIf: "type == 'singleBreakdown'",
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "grid",
        visibleName: "Valores del desglose",
        name: "breakdown_values",
        editable: true,
        size: 12,
        showIf:
          "type == 'singleBreakdown' and chart_type != 'filledShapeChart'",
        cols: [
          {
            type: "text",
            name: "value",
            visibleName: "Valor",
            size: 6,
            //defaultValue: 1,
          },
          {
            type: "pastelColor",
            name: "color",
            visibleName: "Color",
            size: 6,
          },
        ],
      },
      {
        type: "grid",
        visibleName: "Valores del desglose",
        name: "breakdown_values",
        editable: true,
        size: 12,
        showIf:
          "type == 'singleBreakdown' and chart_type == 'filledShapeChart'",
        cols: [
          {
            type: "text",
            name: "value",
            visibleName: "Valor",
            size: 4,
            //defaultValue: 1,
          },
          {
            type: "pastelColor",
            name: "color",
            visibleName: "Color",
            size: 4,
          },
          {
            type: "image",
            name: "shape_file",
            visibleName: "Archivo de la forma",
            size: 4,
          },
        ],
      },
      // {
      //   type: "grid",
      //   visibleName: "Desgloses",
      //   name: "breakdowns",
      //   editable: true,
      //   size: 12,
      //   cols: [
      //     {
      //       type: "text",
      //       name: "name",
      //       size: 4,
      //       visibleName: "name",
      //       //defaultValue: 1,
      //     },
      //     {
      //       type: "picker",
      //       visibleName: "Tipo de gráfico",
      //       name: "chart_type",
      //       size: 8,
      //       defaultValue: "lineChart",
      //       options: [
      //         {
      //           label: "Gráfico de lineas multiples",
      //           value: "multiLineChart",
      //           icon: "multiLineChartCard",
      //         },
      //         {
      //           label: "Gráfico de columnas apiladas",
      //           value: "stackedColumnChart",
      //           icon: "stackedColumnChartCard",
      //         },
      //         {
      //           label: "Gráfico de torta",
      //           value: "pieChart",
      //           icon: "pieChartCard",
      //         },
      //         {
      //           label: "Gráfico de area",
      //           value: "breakdownChart",
      //           icon: "breakdownChartCard",
      //         },
      //         {
      //           label: "Gráfico de relleno",
      //           value: "filledShapeChart",
      //           icon: "filledShapeChartCard",
      //         },
      //       ],
      //       validations: {
      //         maxItems: 1,
      //       },
      //     },
      //   ],
      // },
    ],
  };

  return data;
};
