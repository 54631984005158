import { useState, useEffect } from "react";

import { CheckIcon } from "../../icons/CheckIcon";
import { CloseIcon } from "../../icons/CloseIcon";
import { PencilIcon } from "../../icons/PencilIcon";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import { Snackbar, Alert } from "@mui/material";
import "./PaymentField.scss";
import { ReadonlyPaymentField } from "./ReadonlyPaymentField/ReadonlyPaymentField";
import { EditablePaymentField } from "./EditablePaymentField/EditablePaymentField";
const PaymentField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    editPath,
    onChange,
    tableName,
    name,
    recordId,
    readonly,
    transactionType,
  }: any = props;
  const [data, setData] = useState(defaultValue as any);
  const [enabledEdition, setEnabledEdition] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const handleSave = async () => {
    if (tableName) tableName = tableName.replace(/_/g, "-");
    const url = tableName
      ? `${urls.server}/api/${tableName}/${recordId}/update-${name}`
      : `${urls.server}/api/${editPath}`;
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.post(`${url}`, { data: data });
      const resData = await res.data;

      setEnabledEdition(false);
      setProcessing(false);
    } catch (error: any) {
      setEnabledEdition(false);
      setProcessing(false);
      setAlertMsg(error.toString());
      setOpenAlert(true);
    }
  };
  const enableEdition = () => {
    setEnabledEdition(true);
  };
  useEffect(() => {
    if (onChange) {
      onChange(data);
    }
  }, [data]);
  const handleCloseAlert = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  return (
    <div className="payment-field-container">
      <div className="field-header">
        {(recordId || editPath) && editable && (
          <div>
            {enabledEdition ? (
              <>
                <a onClick={handleSave}>
                  <CheckIcon />
                </a>
                <a onClick={() => setEnabledEdition(false)}>
                  <CloseIcon />
                </a>
              </>
            ) : (
              <>
                <a onClick={enableEdition}>
                  <PencilIcon />
                </a>
              </>
            )}
          </div>
        )}
      </div>
      <div className="field-body">
        <div>
          {readonly ||
            ((recordId || editPath) && editable && !enabledEdition) ||
            ((recordId || editPath) && !editable) ? (
            <ReadonlyPaymentField />
          ) : (
            <EditablePaymentField
              transactionType={transactionType}
              onChange={(value: any) => {
                setData(value);
              }}
            />
          )}
        </div>
      </div>
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={handleCloseAlert}
      >
        <Alert
          variant="filled"
          onClose={handleCloseAlert}
          severity="error"
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export { PaymentField };
