import { useState, useEffect } from "react";
import Fab from "@mui/material/Fab";


import AxiosInterceptor from "../../../../AxiosInterceptor";
//import Dialog from "@mui/material/Dialog";
import { Dialog } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import "./TagsExpandedCard.scss";
import { TagGroupItem } from "../TagGroupItem/TagGroupItem";
import { AddIcon } from "../../../../components/icons/AddIcon";
import Grid from "@mui/material/Grid";
import { InputField } from "../../../../components/fields/InputField/InputField";
import { Spinner } from "../../../../components/Spinner/Spinner";
import { TagsIcon } from "../../../../components/icons/TagsIcon";
import urls from "config/urls";
const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 500,
    width: "80%",
  },
}));
const TagsExpandedCard = (props: any) => {
  const { title, data } = props;
  const [sortBy, setSortBy] = useState("visible_name");
  const [sortDirection, setSortDirection] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [records, setRecords] = useState([] as any);
  const [hasMore, setHasMore] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [groupName, setGroupName] = useState("");

  const loadRecords = async () => {
    if (offset) {
      setLoadingMore(true);
    } else {
      setLoading(true);
    }

    const res = await AxiosInterceptor.get(
      `${urls.server}/api/tag-groups?offset=${offset}&limit=${limit}&sort_by=${sortBy}&sort_direction=${sortDirection}&search_term=${searchTerm}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    );
    const data = await res.data;
    if (offset > 0) {
      setRecords((prev: any) => [...prev, ...data]);
    } else {
      setRecords(data);
    }

    setLoadingMore(false);
    setLoading(false);
    if (data.length < limit) setHasMore(false);
  };
  useEffect(() => {
    loadRecords();
  }, []);
  const createGroup = async () => {
    setProcessing(true);
    try {
      const data = {
        name: groupName,
      };
      const res = await AxiosInterceptor.post(
        `${urls.server}/api/tag-groups`,
        data
      );
      setProcessing(false);
      setFormIsOpen(false);
      loadRecords();
      setGroupName("");
      console.log(res);
    } catch (error) {
      setProcessing(false);
    }
  };
  useEffect(() => {
    if (!formIsOpen) {
      setGroupName("");
    }
  }, [formIsOpen]);
  const classes = useStyles();
  return (
    <div className="tags-expanded-card-container">
      <div className="expanded-card-header">
        <h2>{title}</h2>
      </div>
      <h3>Grupos de etiquetas</h3>

      {loading && (
        <div className="spinner-wrapper">
          <Spinner visible={loading} />
        </div>
      )}
      {!loading && records.length == 0 && (
        <div className="no-content">
          <TagsIcon />
          <p>No hay grupos de etiquetas</p>
        </div>
      )}
      {!loading && records.length > 0 && (
        <>
          <div className="tags-list">
            {records.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <TagGroupItem name={item.name} visibleName={item.visible_name} id={item.id} />
                </div>
              );
              //return <div key={index}>{item.name}</div>;
            })}
          </div>
        </>
      )}

      <Fab
        className="fab"
        color="primary"
        aria-label="add"
        onClick={() => {
          setFormIsOpen(true);
        }}
      >
        <AddIcon />
      </Fab>
      <Dialog
        open={formIsOpen}
        onClose={() => { }}
        className="dialog-form"
        classes={{ paper: classes.dialog }}
      //maxWidth="lg"
      >
        <DialogTitle className="dialog-form-title">
          Nuevo grupo de etiquetas
        </DialogTitle>
        <DialogContent className="dialog-form-content">
          <DialogContentText className="dialog-form-description">
            Ej: para tareas, indicadores, etc.
          </DialogContentText>
          <div className="dialog-form-inputs-wrapper">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                {/* <div className="dialog-form-input-wrapper">
                  <input
                    type="text"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                  />
                </div> */}
                <InputField
                  id="tag-group-name"
                  defaultValue={groupName}
                  label={"Nombre del grupo"}
                  //editable={true}

                  onChange={(value: string) => {
                    console.log("holass", value);
                    setGroupName(value);
                    // setFormConfig((prev: any) => {
                    //   let data: any = { ...prev };
                    //   data.title = value;
                    //   return data;
                    // });
                  }}
                //editable
                />
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <div className="dialog-form-input-wrapper">
                  <input
                    type="text"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                  />
                </div>
              </Grid> */}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setFormIsOpen(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            disabled={processing || !groupName}
            onClick={() => {
              createGroup();
            }}
          >
            Crear grupo
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export { TagsExpandedCard };
