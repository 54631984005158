import React, { useState, useEffect } from "react";
import { Dialog, IconButton, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon } from "../../icons/CloseIcon";

import "./IconPickerModal.scss";
import { HeaderSearchbar } from "../../HeaderSearchbar/HeaderSearchbar";
import Icon from "components/Icon/Icon";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const IconPickerModal = ({ open, setOpen, onClose }: any) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  //const [filteredItems, setFilteredItems] = useState([]);
  const icons = [
    {
      name: "download",
      visibleName: "Descargar",
    },
    {
      name: "upload",
      visibleName: "Subir",
    },
    {
      name: "email",
      visibleName: "Correo Electrónico",
    },
    {
      name: "wallet",
      visibleName: "Billetera",
    },
    {
      name: "checklist",
      visibleName: "Lista de Verificación",
    },
    {
      name: "paymentAllocation",
      visibleName: "Asignación de Pago",
    },
    {
      name: "completedConstruction",
      visibleName: "Construcción Completada",
    },
    {
      name: "constructionInProgress",
      visibleName: "Construcción en Progreso",
    },
    {
      name: "constructionInPause",
      visibleName: "Construcción en Pausa",
    },
    {
      name: "color",
      visibleName: "Color",
    },
    {
      name: "export",
      visibleName: "Exportar",
    },
    {
      name: "work",
      visibleName: "Trabajo",
    },
    {
      name: "employment",
      visibleName: "Empleo",
    },
    {
      name: "unemployment",
      visibleName: "Desempleo",
    },
    {
      name: "text",
      visibleName: "Texto",
    },
    {
      name: "tags",
      visibleName: "Etiquetas",
    },
    {
      name: "thumbnail",
      visibleName: "Miniatura",
    },
    {
      name: "address",
      visibleName: "Dirección",
    },
    {
      name: "date",
      visibleName: "Fecha",
    },
    {
      name: "days",
      visibleName: "Días",
    },
    {
      name: "months",
      visibleName: "Meses",
    },
    {
      name: "statuses",
      visibleName: "Estados",
    },
    // {
    //   name: "checkCircleOutline",
    //   visibleName: "Círculo",
    // },
    // {
    //   name: "radioButtonUnchecked",
    //   visibleName: "Botón de Radio sin Marcar",
    // },
    {
      name: "users",
      visibleName: "Usuarios",
    },
    {
      name: "grid",
      visibleName: "Rejilla",
    },
    {
      name: "citrus",
      visibleName: "Cítricos",
    },
    {
      name: "livestock",
      visibleName: "Ganado",
    },
    {
      name: "cerealsAndGrains",
      visibleName: "Cereales y Granos",
    },
    {
      name: "energy",
      visibleName: "Energía",
    },
    {
      name: "indicatorCard",
      visibleName: "Tarjeta Indicadora",
    },
    {
      name: "rankingCard",
      visibleName: "Tarjeta de Clasificación",
    },

    {
      name: "cart",
      visibleName: "Carrito de compra"
    },
    {
      name: "educationalUnits",
      visibleName: "Unidades educativas"
    },
    {
      name: "studentLevelOne",
      visibleName: "Estudiante nivel uno"
    },
    {
      name: "studentLevelTwo",
      visibleName: "Estudiante nivel dos"
    },
    {
      name: "studentLevelThree",
      visibleName: "Estudiante nivel tres"
    },
    {
      name: "studentLevelFour",
      visibleName: "Estudiante nivel cuatro"
    }
    // ... Continúa de la misma manera para el resto de los nombres
  ];

  const filteredIcons = icons.filter((icon) =>
    icon.visibleName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        setOpen(false);
        onClose({
          refresh: refreshOnClose,
        });
      }}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: "#f2f2f4",
        },
      }}
      className="icon-picker-modal-container"
    >
      <div className="modal-header">
        <div className="modal-title">
          <h2>Seleccionar icono</h2>
        </div>
        <HeaderSearchbar
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
        />
        <div className="modal-end-btns">
          <IconButton
            color="inherit"
            onClick={() => {
              setOpen(false);
              onClose(null);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="modal-body">
        <div>
          {filteredIcons.map((item) => (
            <a
              key={item.name}
              onClick={() => {
                onClose(item);
                setOpen(false);
              }}
              style={{ display: "block", margin: "10px" }} // Estilos adicionales si es necesario
            >
              <Icon name={item.name} />
              <span>{item.visibleName}</span>
            </a>
          ))}
        </div>
      </div>
    </Dialog>
  );
};

export { IconPickerModal };
