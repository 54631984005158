import React, { useEffect, useState } from "react";
import "./VideoField.scss";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import { IconButton } from "@mui/material";
import { DeleteIcon } from "components/icons/DeleteIcon";
import { Spinner } from "components/Spinner/Spinner";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";

const VideoField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    editPath,
    id,
    onChange,
    recordId,
    accept = "video/*",
    readonly,
    name,
    folder = "videos",
    mode,
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [processing, setProcessing] = useState(false);

  const removeVideo = () => {
    setValue(null);
  };
  useEffect(() => {
    if (onChange) {
      console.log("value", value);
      onChange(value);
    }
  }, [value]);
  useEffect(() => {
    console.log(defaultValue);
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let reader = new FileReader();
      const file: File = e.target.files[0];
      const extension = file.name.split(".").pop();
      reader.readAsDataURL(file);
      reader.onload = await function () {
        console.log(reader.result);
        const data = {
          content: reader.result,
          extension,
          folder: folder,
        };
        saveVideo(data);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    } else {
      console.log("No Video selected");
    }
  };

  const saveVideo = async ({ content, extension, folder }: any) => {
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.post(`${urls.server}/api/videos`, {
        base64_video: content,
        extension,
        folder,
      });
      const resData = await res.data;
      setValue(resData?.path);
      setProcessing(false);
    } catch (error: any) {
      setProcessing(false);
    }
  };
  return (
    <div className="video-field-container">
      {label && (
        <label>
          <span>{label}</span>
        </label>
      )}
      <div className="content">
        {readonly ||
          (mode == 'edit' && editable && !isEditEnabled) ||
          (mode == 'edit' && !editable) ? (
          <div className="readonly-content">
            {value ? (
              <div className="video-preview-wrapper">
                <video src={`${urls.server}${value}`} autoPlay controls>
                  El navegador no admite el elemento <code>video</code>.
                </video>
              </div>
            ) : (
              <div className="no-value">
                <VideoCameraBackIcon />
                <span>Sin Video</span>
              </div>
            )}
          </div>
        ) : (
          <div className="editable-content">
            {processing ? (
              <div className="spinner-wrapper">
                <Spinner size="sm" />
              </div>
            ) : (
              <>
                {!value && (
                  <div className="add-image-btn">
                    <input
                      id={id}
                      accept={accept}
                      type="file"
                      onChange={handleChange}
                    />
                    <div>
                      <div>
                        <VideoCameraBackIcon />
                        <span>Seleccionar Video</span>
                      </div>
                      <label htmlFor={id}></label>
                    </div>
                  </div>
                )}
                {value && (
                  <div className="video-preview-wrapper">
                    <video src={`${urls.server}${value}`} autoPlay controls>
                      El navegador no admite el elemento <code>video</code>.
                    </video>

                    <IconButton aria-label="delete" onClick={removeVideo}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
      {editable && (
        <div className="btns">
          {!readonly != true || (mode == 'edit' && editable) && (

            <FieldEditControls
              value={value}
              fieldName={label}
              editPath={editPath}
              onEditStart={() => {
                setIsEditEnabled(true);
              }}
              onEditCancel={(originalValue) => {
                setIsEditEnabled(false);
                setValue(originalValue);
              }}
              onEditEnd={(success: boolean, originalValue) => {
                setIsEditEnabled(false);
                if (!success) {
                  setValue(originalValue);
                }
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export { VideoField };
