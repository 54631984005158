import React from "react";
import { SvgIcon } from "@mui/material";
const ReduceIcon = (props: any) => (
  <SvgIcon style={{ fontSize: props?.size }} {...props} viewBox="0 0 32 32">
    <path fill={props?.color} d="M17.009 23.495h-7.855l-1.522 4.827h-4.534l7.701-22.38h4.596l7.67 22.38h-4.534l-1.522-4.827zM10.261 19.975h5.641l-2.767-8.792h-0.092l-2.782 8.792z"></path>
    <path fill={props?.color} d="M19.796 8.564v3.404c0 0 2.586 0 9.408 0v-3.404c0 0-2.586 0-9.408 0z"></path>
  </SvgIcon>
);

export { ReduceIcon };
