import { useState, useEffect } from "react";
import { CloseIcon } from "components/icons/CloseIcon";
import { PencilIcon } from "components/icons/PencilIcon";
import { EditableSimplePaymentField } from "./EditableSimplePaymentField/EditableSimplePaymentField";
import { ReadonlySimplePaymentField } from "./ReadonlySimplePaymentField/ReadonlySimplePaymentField";
import { useSessionStorage } from "hooks/useSessionStorage";
import { useCancelEdit } from "hooks/useCancelEdit";
import { useUpdateFieldValue } from "hooks/useUpdateFieldValue";
import { UpdateFieldValueBtn } from "components/UpdateFieldValueBtn/UpdateFieldValueBtn";
import "./SimplePaymentField.scss";
import { InformationMessage } from "../../InformationMessage/InformationMessage";

const SimplePaymentField = (props: any) => {
  let {
    defaultValue = {
      paymentMethods: [
        {
          name: "cash",
          visibleName: "Efectivo",
          amount: 0,
        },
        {
          name: "credit_card",
          visibleName: "Tarjeta de crédito",
          amount: 0,
        },
        {
          name: "debit_card",
          visibleName: "Tarjeta de débito",
          amount: 0,
        },
        {
          name: "mercado_pago",
          visibleName: "Mercado Pago",
          amount: 0,
        },
        {
          name: "bank_payment",
          visibleName: "Pago bancario",
          amount: 0,
        },
      ],
      totalPaid: 0,
    },
    editable,
    label,
    editPath,
    onChange,
    tableName,
    name,
    recordId,
    readonly,
    formData,
    mode
  }: any = props;

  const [data, setData] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const sessionStorageKey = `${label}${tableName}`;
  const [storageValue, setStorageValue, removeValue] = useSessionStorage(
    sessionStorageKey,
    ""
  );
  const {
    enabledEdition,
    processing,
    openAlert,
    alertMsg,
    severity,
    saveValue,
    setProcessing,
    setEnabledEdition,
    setOpenAlert,
  } = useUpdateFieldValue({
    setValue: setData,
    removeValue,
    storageValue,
    sessionStorageKey,
    defaultValue,
    tableName,
    name,
    recordId,
    editPath,
  });
  const { handleClose } = useCancelEdit({
    setValue: setData,
    setEnabled: setEnabledEdition,
  });
  const enableEdition = () => {
    setEnabledEdition(true);
    setStorageValue(defaultValue);
  };

  useEffect(() => {
    if (onChange) {
      onChange(data);
    }
  }, [data]);

  return (
    <div className="simple-payment-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {(recordId || editPath) && editable && (
          <div>
            {!readonly && (recordId || editPath) && editable && (
              <>
                {enabledEdition ? (
                  <>
                    {
                      <UpdateFieldValueBtn
                        processing={processing}
                        saveValue={saveValue}
                        prevValue={storageValue}
                        currentValue={defaultValue}
                      />
                    }

                    <a onClick={() => handleClose(storageValue, false)}>
                      <CloseIcon />
                    </a>
                  </>
                ) : (
                  <>
                    <a onClick={enableEdition}>
                      <PencilIcon />
                    </a>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div className="field-body">
        <div>
          {readonly ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <ReadonlySimplePaymentField
              data={defaultValue}
              formData={formData}
            />
          ) : (
            <EditableSimplePaymentField
              defaultData={defaultValue}
              formData={formData}
              onChange={(value: any) => {
                setData(value);
              }}
            />
          )}
        </div>
      </div>
      <InformationMessage
        open={openAlert}
        message={alertMsg}
        severity={severity}
        onClose={setOpenAlert}
      />
    </div>
  );
};

export default SimplePaymentField;
