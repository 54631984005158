import React from "react";
import { SortableHandle } from "react-sortable-hoc";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useTextEditorField } from "./TextEditorFieldContext";

import { CircleIcon } from "../../icons/CircleIcon";

import { ColorPickerBtn } from "../../ColorPickerBtn/ColorPickerBtn";
import { RegularIcon } from "components/icons/RegularIcon";
import { RegularItalicIcon } from "components/icons/RegularItalicIcon";
import { BoldIcon } from "components/icons/BoldIcon";
import { BoldItalicIcon } from "components/icons/BoldItalicIcon";
import { AlignLeftIcon } from "components/icons/AlignLeftIcon";
import { AlignCenterIcon } from "components/icons/AlignCenterIcon";
import { AlignRightIcon } from "components/icons/AlignRightIcon";
import { AlignJustifyIcon } from "components/icons/AlignJustifyIcon";
import { Table1Icon } from "components/icons/Table1Icon";
import { Table2Icon } from "components/icons/Table2Icon";
import { SquareIcon } from "components/icons/SquareIcon";
import { SquareOutlineIcon } from "components/icons/SquareOutlineIcon";
import { CircleOutlineIcon } from "components/icons/CircleOutlineIcon";
import { CheckmarkIcon } from "components/icons/CheckmarkIcon";
import { EnlargeIcon } from "components/icons/EnlargeIcon";
import { ReduceIcon } from "components/icons/ReduceIcon";

const DragHandle = SortableHandle(() => {
  return (
    <IconButton>
      <DragIndicatorIcon />
    </IconButton>
  );
});
const TextEditorFieldItemBtns = ({ i, item }: any) => {
  const {
    handleDeleteItem,
    handleChangeFontStyle,
    handleChangeFontSize,
    handleChangeTextAlign,
    handleToggleWithHeader,
    handleChangeMarkerType,
    handleChangeTextColor,
  } = useTextEditorField();

  // const handleChangeTextColor = (slectedColor: string, id: any) => {
  //   console.log(slectedColor);
  //   console.log(id);

  // };
  return (
    <div className="text-editor-field-item-btns">
      <span>{item.label}</span>
      <div>
        {item?.type == "text" && (
          <IconButton
            aria-label="change font style"
            onClick={() => handleChangeFontStyle(i)}
          >
            {item?.fontStyle === "regular" && <RegularIcon />}
            {item?.fontStyle === "regular_italic" && <RegularItalicIcon />}
            {item?.fontStyle === "bold" && <BoldIcon />}
            {item?.fontStyle === "bold_italic" && <BoldItalicIcon />}
          </IconButton>
        )}
        {item?.type == "text" && (
          <IconButton
            aria-label="change font style"
            onClick={() => handleChangeTextAlign(i)}
          >
            {item?.textAlign === "left" && <AlignLeftIcon />}
            {item?.textAlign === "center" && <AlignCenterIcon />}
            {item?.textAlign === "right" && <AlignRightIcon />}
            {item?.textAlign === "justify" && <AlignJustifyIcon />}
          </IconButton>
        )}
        {item?.type == "text" && (
          <IconButton
            aria-label="change font size"
            onClick={() => handleChangeFontSize(i, "enlarge")}
          >
            <EnlargeIcon />
          </IconButton>
        )}
        {item?.type == "text" && (
          <IconButton
            aria-label="change font size"
            onClick={() => handleChangeFontSize(i, "reduce")}
          >
            <ReduceIcon />
          </IconButton>
        )}
        {item?.type == "text" && (
          <ColorPickerBtn
            id={i}
            handleChangeColor={handleChangeTextColor}
            defaultColor="#333333"
          ></ColorPickerBtn>
        )}
        {item?.type == "table" && (
          <IconButton
            aria-label="change font size"
            onClick={() => handleToggleWithHeader(i)}
          >
            {!item?.withHeader && <Table1Icon />}
            {item?.withHeader && <Table2Icon />}
          </IconButton>
        )}
        {item?.type == "list" && (
          <IconButton
            aria-label="change font style"
            onClick={() => handleChangeMarkerType(i)}
          >
            {item?.markerType === "square" && <SquareIcon />}
            {item?.markerType === "squareOutline" && <SquareOutlineIcon />}
            {item?.markerType === "circle" && <CircleIcon />}
            {item?.markerType === "circleOutline" && <CircleOutlineIcon />}
            {item?.markerType === "checkMark" && <CheckmarkIcon />}
          </IconButton>
        )}
        <IconButton aria-label="delete" onClick={() => handleDeleteItem(i)}>
          <DeleteIcon />
        </IconButton>
        <DragHandle />
      </div>
    </div>
  );
};

export { TextEditorFieldItemBtns };
