import React from "react";
import { Spinner } from "components/Spinner/Spinner";
import { CheckIcon } from "components/icons/CheckIcon";
import { IconButton } from "@mui/material";
import { useCompare } from "hooks/useCompare";

interface UpdateFieldValueBtnProps {
  processing: boolean; // es el loading del sipner
  saveValue: (val: any) => void; //es el metodo que guarda el endpoint
  prevValue: any; //es el valor que se guardo en el session store al momento de hacer click en el boton editar
  currentValue: any; //es el defaultvalue
}

export const UpdateFieldValueBtn: React.FC<UpdateFieldValueBtnProps> = ({
  processing,
  saveValue,
  prevValue,
  currentValue,
}) => {
  const { isEqual } = useCompare({
    prev: prevValue,
    current: currentValue,
  });
  return (
    <IconButton
      color="primary"
      size="large"
      disabled={isEqual}
      onClick={() => saveValue(currentValue)}
    >
      {processing ? <Spinner size="sm" /> : <CheckIcon />}
    </IconButton>
  );
};
