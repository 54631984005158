import { useState, useEffect } from "react";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import { IconButton } from "@mui/material";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import DeleteIcon from "@mui/icons-material/Delete";
import "./ImageField.scss";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import { Spinner } from "components/Spinner/Spinner";
const ImageField = (props: any) => {
  let {
    editable,
    label,
    editPath,
    onChange,
    name,
    recordId,
    accept = "image/*",
    id,
    defaultValue,
    folder = "images",
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [processing, setProcessing] = useState(false);



  useEffect(() => {
    if (onChange) {
      console.log("value", value);
      onChange(value);
    }
  }, [value]);
  useEffect(() => {
    console.log(defaultValue);
    setValue(defaultValue);
  }, [defaultValue]);


  const handleChange = async (e: any) => {
    console.log(URL.createObjectURL(e.target.files[0]));
    var reader = new FileReader();
    const file: File = e.target.files[0];
    const extension = file.name.split(".").pop();
    console.log(extension);
    reader.readAsDataURL(file);
    reader.onload = await function () {
      console.log(reader.result);
      const data = {
        content: reader.result,
        extension,
        folder: folder,
      };
      saveImage(data);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const removeImg = () => {
    setValue(null);
  };

  const saveImage = async ({ content, extension, folder }: any) => {
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.post(`${urls.server}/api/images`, {
        base64_image: content,
        extension,
        folder,
      });
      const resData = await res.data;
      setValue(resData?.path);
      setProcessing(false);
    } catch (error: any) {
      setProcessing(false);
    }
  };
  return (
    <div className="images-field-container">
      {label && (
        <label>
          <span>{label}</span>
        </label>
      )}

      <div className="content">
        {(recordId || editPath) && editable && !isEditEnabled ? (
          <div className="readonly-content">
            {value ? (
              <div className="img-preview-wrapper">
                <img src={`${urls.server}${value}`} />
              </div>
            ) : (
              <div className="no-value">
                <InsertPhotoIcon />
                <span>Sin imagen</span>
              </div>
            )}
          </div>
        ) : (
          <div className="editable-content">
            {processing ? (
              <div className="spinner-wrapper">
                <Spinner size="sm" />
              </div>
            ) : (
              <>
                {!value && (
                  <div className="add-image-btn">
                    <input
                      id={id}
                      accept={accept}
                      type="file"
                      onChange={handleChange}
                    />
                    <div>
                      <div>
                        <InsertPhotoIcon />
                        <span>Seleccionar imagen</span>
                      </div>
                      <label htmlFor={id}></label>
                    </div>
                  </div>
                )}
                {value && (
                  <div className="img-preview-wrapper">
                    <img src={`${urls.server}${value}`} />
                    <IconButton aria-label="delete" onClick={removeImg}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>

      {(recordId || editPath) && editable && (
        <div className="btns">
          <FieldEditControls
            value={value}
            fieldName={label}
            editPath={editPath}
            onEditStart={() => {
              setIsEditEnabled(true);
            }}
            onEditCancel={(originalValue) => {
              setIsEditEnabled(false);
              setValue(originalValue);
            }}
            onEditEnd={(success: boolean, originalValue) => {
              setIsEditEnabled(false);
              if (!success) {
                setValue(originalValue);
              }
            }}
          />
        </div>
      )}


    </div>
  );
};

export { ImageField };
