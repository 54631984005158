import { useEffect, useState } from "react";
export const useCompare = <T>({
  prev,
  current,
}: {
  prev: T;
  current: T;
}): { isEqual: boolean } => {
  const [isEqual, setIsEqual] = useState(false);
  useEffect(() => {
    if (prev) {
      setIsEqual(JSON.stringify(prev) === JSON.stringify(current));
    }
  }, [prev, current]);

  return { isEqual };
};
