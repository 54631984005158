import React from 'react'
import './ConfigurationItemEditor.scss';
import { RadioField } from 'components/fields/RadioField/RadioField';
import { SelectField } from 'components/fields/SelectField/SelectField';
import { InputField } from 'components/fields/InputField/InputField';
import { LongTextField } from 'components/fields/LongTextField/LongTextField';
import { CheckboxField } from 'components/fields/CheckboxField/CheckboxField';
import { SliderField } from 'components/fields/SliderField/SliderField';
import { CloseIcon } from 'components/icons/CloseIcon';
import { CheckIcon } from 'components/icons/CheckIcon';
import { GridField } from 'components/fields/GridField/GridField';
import ConfigurationField from 'components/fields/ConfigurationField/ConfigurationField';
import ListField from 'components/fields/ListField/ListField';

interface ConfigurationItemEditorProps {
    configurationType: string;
    data: any;
    isVisible: boolean;
    onHide: (data?: any) => void
}
const ConfigurationItemEditor: React.FC<ConfigurationItemEditorProps> = ({ data = {}, isVisible = false, configurationType, onHide }) => {
    const [modifiedData, setModifiedData] = React.useState(data);
    const [isSaveButtonEnabled, setIsSaveButtonEnabled] = React.useState(false);
    const handleChange = (attributeName: string, value: any) => {
        setModifiedData((prevData: any) => ({
            ...prevData,
            configurable_attributes: {
                ...prevData?.configurable_attributes,
                [attributeName]: value,
            },
        }));
        setIsSaveButtonEnabled(true);
    };
    React.useEffect(() => {
        //if (isVisible) {
        setModifiedData(data);
        //}

        setIsSaveButtonEnabled(false);
        console.log(data);
    }, [
        data,
        //isVisible
    ]);
    return (
        <div className={`configuration-item-editor-container isVisible ${isVisible ? "visible" : "hidden"} `}>
            {
                isVisible && <>
                    <a onClick={() => { onHide() }}>
                        <CloseIcon />
                    </a>
                    <a onClick={() => { if (isSaveButtonEnabled) onHide(modifiedData) }} className={isSaveButtonEnabled ? "" : "disabled"}>
                        <CheckIcon />
                    </a>
                </>
            }
            <div>
                {
                    data?.configurable_attributes &&
                    <>
                        {
                            Object.keys(data?.configurable_attributes).map((keyName, i) => (
                                <div key={i}>
                                    {keyName == "name" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Nombre"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "visibleName" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Nombre a mostar"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "defaultValue" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Valor por defecto"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "size" && (
                                        <>
                                            <SliderField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Tamaño"}
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "editable" && (
                                        <>
                                            <CheckboxField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Se edita"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "sortable" && (
                                        <>
                                            {/* {JSON.stringify(data)} */}
                                            <CheckboxField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Se ordena"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "searchable" && (
                                        <>
                                            <CheckboxField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Es buscable"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {configurationType == 'cols' && data.type == 'status' && keyName == "options" && (
                                        <>
                                            <GridField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Estados"}
                                                description=""
                                                forceMobileView={true}
                                                cols={[
                                                    {
                                                        type: "text",
                                                        name: "name",
                                                        visibleName: "Nombre",
                                                    },
                                                    {
                                                        type: "text",
                                                        name: "visibleName",
                                                        visibleName: "Nombre a mostrar",
                                                    },
                                                    {
                                                        type: "iconPicker",
                                                        name: "icon",
                                                        size: 2,
                                                        visibleName: "Icono",
                                                    },
                                                    {
                                                        type: "pastelColor",
                                                        name: "color",
                                                        visibleName: "Color",
                                                        size: 4,
                                                    },


                                                ]}
                                                onChange={(value: any) => {
                                                    console.log("secambio", value);
                                                    handleChange(keyName, value)
                                                }}
                                            />

                                        </>
                                    )}
                                    {keyName == "editableIf" && (
                                        <>
                                            <LongTextField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Se edita si"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "showIf" && (
                                        <>
                                            <LongTextField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Mostrar si"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "showDiff" && (
                                        <>
                                            <CheckboxField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Mostar Hace.../Falta..."}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "prepend" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Anteponer"}
                                            placeholder="EJ: $"
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "append" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Postponer"}
                                            placeholder="EJ: %"
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "primaryKey" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Llave principal"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "secondaryKey" && (
                                        <InputField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Llave secundaria"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "tagGroup" && (
                                        <SelectField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Grupo de etiqueta"}
                                            searchPath={"tag-groups"}
                                            primaryKey={"visible_name"}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "transactionType" && (
                                        <RadioField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Tipo de transacción"}
                                            //options={["Venta", "Compra"]}
                                            options={
                                                [
                                                    {
                                                        name: "sale",
                                                        visibleName: "Venta",
                                                    },
                                                    {
                                                        name: "purchase",
                                                        visibleName: "Compra",
                                                    }
                                                ]
                                            }
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}

                                    {keyName == "documentType" && (
                                        <RadioField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Tipo de documento"}
                                            options={
                                                [
                                                    {
                                                        name: "invoice",
                                                        visibleName: "Factura",
                                                    },
                                                    {
                                                        name: "debitNote",
                                                        visibleName: "Nota de débito",
                                                    },
                                                    {
                                                        name: "creditNote",
                                                        visibleName: "Nota de crédito",
                                                    }
                                                ]
                                            }
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "formFields" && (
                                        <ConfigurationField
                                            defaultValue={data?.configurable_attributes[keyName]}
                                            label={"Campos del formulario"}
                                            configurationType={'fields'}
                                            onChange={(value: any) => handleChange(keyName, value)}
                                        />
                                    )}
                                    {keyName == "formTitle" && (
                                        <>
                                            <InputField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Título del formulario"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "searchPath" && (
                                        <>
                                            <InputField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Ruta de busqueda"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "createPath" && (
                                        <>
                                            <InputField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Ruta de creación"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                    {keyName == "searchFields" && (
                                        <>
                                            <ListField
                                                defaultValue={data?.configurable_attributes[keyName]}
                                                label={"Campos de búsqueda"}
                                                description=""
                                                onChange={(value: any) => handleChange(keyName, value)}
                                            />
                                        </>
                                    )}
                                </div>
                            ))
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default ConfigurationItemEditor