import { useState, useEffect } from "react";
import { CheckIcon } from "../../icons/CheckIcon";
import { CloseIcon } from "../../icons/CloseIcon";
import { PencilIcon } from "../../icons/PencilIcon";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import "./CheckboxesField.scss";

import { Spinner } from "../../Spinner/Spinner";
const CheckboxesField = (props: any) => {
  const {
    editable,
    label,
    editPath,
    listPath,
    onChange,
    description,
    data = [],
  }: any = props;

  const [enabledEdition, setEnabledEdition] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataCopy, setDataCopy] = useState(data);

  const handleSave = () => {
    setEnabledEdition(false);
  };
  const enableEdition = () => {
    setEnabledEdition(true);
  };

  const selectItem = (index: number) => {
    const data = [...dataCopy];
    data[index].selected = !data[index].selected;
    setDataCopy(data);
  };
  useEffect(() => {
    if (onChange) onChange(dataCopy);
  }, [dataCopy]);
  const loadData = async () => {
    setLoading(true);
    let url = `${urls.server}/api/${listPath}`;

    const res = await AxiosInterceptor.get(url);
    const data = await res.data;
    setLoading(false);
    setDataCopy(data);
  };
  useEffect(() => {
    if (dataCopy.length == 0) {
      loadData();
    }
  }, []);
  return (
    <div className="checkboxes-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {editable && (
          <div>
            {enabledEdition ? (
              <>
                <a onClick={handleSave}>
                  <CheckIcon />
                </a>
                <a onClick={() => setEnabledEdition(false)}>
                  <CloseIcon />
                </a>
              </>
            ) : (
              <>
                <a onClick={enableEdition}>
                  <PencilIcon />
                </a>
              </>
            )}
          </div>
        )}
      </div>
      <div className="field-body">
        {loading ? (
          <div className="spinner-wrapper">
            <Spinner visible={loading} size="sm" />
          </div>
        ) : (
          <>
            {editable && !enabledEdition ? (
              <div className="readonly-items"></div>
            ) : (
              <div className="editable-items">
                {dataCopy.map((item: any, index: number) => {
                  return (
                    <a key={index} onClick={() => selectItem(index)}>
                      <span style={{ fontWeight: item.selected ? 700 : 400 }}>
                        {item.name}
                      </span>
                      {item.selected ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                    </a>
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export { CheckboxesField };
